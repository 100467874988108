import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  ButtonProps,
  Card,
  CardActions,
  CardContent,
  CardProps,
  Chip,
  createTheme,
  Grid,
  ScopedCssBaseline,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { BoxProps } from '@mui/system'
import React, { ElementType, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LabeledItem } from '../components/LabeledItem'
import { RouterLink } from '../components/RouterLink'
import { useGetSelf } from '../hooks/swr'
import { useGlobalErrorHandler } from '../hooks/useGlobalErrorHandler'
import { useSession } from '../hooks/useSession'
import { useThemeMode } from '../hooks/useThemeMode'
import ProductBuildingBlocks from '../images/product-building-blocks.jpg'
import ProductChocolate from '../images/product-chocolate.jpg'
import ProductCoffee from '../images/product-coffee.jpg'
import ProductHandbag from '../images/product-handbag.jpg'
import ProductPhone from '../images/product-phone.jpg'
import ProductSneaker from '../images/product-sneaker.jpg'
import { postUsers, postWishlists } from '../utils/endpoints'
import { mutateSelf, mutateWishlist } from '../utils/mutations'
import { darkTheme, lightTheme } from '../utils/themes'

export interface TeaserProps {
  title: string
  description: string
  action: string
  wishlistTitle: string
}

export const Teaser: React.FC<TeaserProps> = ({
  title,
  description,
  action,
  wishlistTitle,
}) => {
  const [i18n] = useTranslation(['common'])
  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('lg'))
  const { themeMode } = useThemeMode()

  const teaserTheme = useMemo(() => {
    const background =
      themeMode === 'light'
        ? lightTheme.palette.primary.main
        : darkTheme.palette.primary.main

    return createTheme(darkTheme, {
      palette: {
        background: { default: background },
      },
    })
  }, [themeMode])

  return (
    <ThemeProvider theme={teaserTheme}>
      <ScopedCssBaseline
        component={Box}
        overflow="hidden"
        position="relative"
        sx={{
          '&::before': {
            background: `radial-gradient(closest-side, ${theme.palette.primary.light} 60%, rgba(0, 0, 0, 0) 100%)`,
            bottom: '-50%',
            content: "''",
            height: '100%',
            left: '-40%',
            position: 'absolute',
            transform: 'scale(1.75)',
            width: '100%',
            zIndex: 0,
          },
          '&::after': {
            background: `radial-gradient(closest-side, ${theme.palette.primary.dark} 0%, rgba(0, 0, 0, 0) 100%)`,
            bottom: '-70%',
            content: "''",
            height: '100%',
            position: 'absolute',
            right: '-50%',
            transform: 'scale(1.5)',
            width: '100%',
            zIndex: 0,
          },
        }}
      >
        <Grid container marginX="auto" maxWidth={1800}>
          <Grid
            item
            xs={12}
            lg={6}
            order={{ xs: 1, lg: 2 }}
            alignSelf="center"
            zIndex={1}
            sx={{ px: { xs: 2, lg: 8 }, py: 8 }}
          >
            <Box maxWidth={700} marginX="auto">
              <Typography
                variant="h2"
                component="h1"
                sx={{
                  fontSize: large ? '3.75rem' : '3rem',
                  hyphens: 'auto',
                }}
              >
                {title}
              </Typography>
              <Typography
                component="p"
                variant="h5"
                paragraph
                sx={{ opacity: 0.6 }}
              >
                {i18n('common:components.teaser.subtitle')}
              </Typography>
              <Typography>{description}</Typography>
              <CreateWishlistButton
                wishlistTitle={wishlistTitle}
                label={action}
                sx={{ mt: 6 }}
              />
              <Typography mt={4}>
                {i18n('common:components.teaser.action.login.description')}{' '}
                <RouterLink color="inherit" to="/start">
                  {i18n('common:components.teaser.action.login.label')}
                </RouterLink>
              </Typography>
            </Box>
          </Grid>
          <TeaserCards
            component={Grid}
            item
            xs={12}
            lg={6}
            order={{ xs: 2, lg: 1 }}
          />
        </Grid>
      </ScopedCssBaseline>
    </ThemeProvider>
  )
}

function TeaserCards<A extends ElementType>(props: BoxProps<A>) {
  const [i18n] = useTranslation(['common'])
  const { themeMode } = useThemeMode()
  const theme = themeMode === 'light' ? lightTheme : darkTheme

  return (
    <ThemeProvider theme={theme}>
      <Box
        {...props}
        aria-hidden
        height={800}
        position="relative"
        sx={{ ...props.sx, pointerEvents: 'none' }}
      >
        <TeaserCard
          title={i18n('common:components.teaser.item.buildingBlocks.title')}
          image={ProductBuildingBlocks}
          description={i18n(
            'common:components.teaser.item.buildingBlocks.description',
          )}
          price={i18n('common:components.teaser.item.buildingBlocks.price')}
          quantity="1"
          shop={i18n('common:components.teaser.item.buildingBlocks.shop')}
          elevation={5}
          scale={1}
          position={{ left: '50%', top: '55%' }}
          sx={{ zIndex: 2 }}
        />
        <TeaserCard
          title={i18n('common:components.teaser.item.smartphone.title')}
          image={ProductPhone}
          description={i18n(
            'common:components.teaser.item.smartphone.description',
          )}
          price={i18n('common:components.teaser.item.smartphone.price')}
          quantity="1"
          shop={i18n('common:components.teaser.item.smartphone.shop')}
          elevation={3}
          scale={0.9}
          position={{ left: '90%', top: '30%' }}
          sx={{ zIndex: 1 }}
        />
        <TeaserCard
          title={i18n('common:components.teaser.item.coffeeBeans.title')}
          image={ProductCoffee}
          description={i18n(
            'common:components.teaser.item.coffeeBeans.description',
          )}
          price={i18n('common:components.teaser.item.coffeeBeans.price')}
          quantity="5"
          shop={i18n('common:components.teaser.item.coffeeBeans.shop')}
          elevation={3}
          scale={0.6}
          position={{ left: '-60%', top: '10%' }}
        />
        <TeaserCard
          title={i18n('common:components.teaser.item.handbag.title')}
          image={ProductHandbag}
          price={i18n('common:components.teaser.item.handbag.price')}
          quantity="1"
          shop={i18n('common:components.teaser.item.handbag.shop')}
          elevation={3}
          scale={0.65}
          position={{ left: '100%', top: '0%' }}
        />
        <TeaserCard
          title={i18n('common:components.teaser.item.chocolate.title')}
          image={ProductChocolate}
          price={i18n('common:components.teaser.item.chocolate.price')}
          quantity="∞"
          shop={i18n('common:components.teaser.item.chocolate.shop')}
          elevation={3}
          scale={0.5}
          position={{ left: '100%', top: '100%' }}
        />
        <TeaserCard
          title={i18n('common:components.teaser.item.sneaker.title')}
          image={ProductSneaker}
          description={i18n(
            'common:components.teaser.item.sneaker.description',
          )}
          price={i18n('common:components.teaser.item.sneaker.price')}
          quantity="1"
          shop={i18n('common:components.teaser.item.sneaker.shop')}
          elevation={3}
          scale={0.75}
          position={{ left: '-10%', top: '80%' }}
        />
      </Box>
    </ThemeProvider>
  )
}

interface TeaserCardProps extends CardProps {
  title: string
  image: string
  description?: string
  price: string
  quantity: string
  shop: string
  elevation: number
  position: { left: string; top: string }
  scale: number
}

const TeaserCard: React.FC<TeaserCardProps> = ({
  title,
  image,
  description,
  price,
  quantity,
  shop,
  elevation,
  position,
  scale,
  ...props
}) => {
  const [i18n] = useTranslation(['common'])
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down('sm'))

  const translate = (position: string) =>
    position.startsWith('-') ? position.substring(1) : '-' + position

  return (
    <Card
      container
      component={Grid}
      width={700}
      elevation={elevation}
      {...props}
      sx={{
        ...props.sx,
        left: position.left,
        position: 'absolute',
        top: position.top,
        transform: `translate(${translate(position.left)}, ${translate(
          position.top,
        )}) scale(${small ? scale * 0.75 : scale})`,
      }}
    >
      <Grid
        item
        component="img"
        height={220}
        width={220}
        src={image}
        sx={{ background: (theme) => theme.palette.background.default }}
      />
      <Grid item xs display="flex" flexDirection="column">
        <CardContent sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Box flex={1}>
            <Typography variant="h5" paragraph>
              {title}
            </Typography>
            {description && <Typography paragraph>{description}</Typography>}
          </Box>
          <Box display="flex" gap={3}>
            <LabeledItem
              label={i18n('common:components.teaser.item.common.price')}
            >
              <Typography>{price}</Typography>
            </LabeledItem>
            <LabeledItem
              label={i18n('common:components.teaser.item.common.quantity')}
            >
              <Typography>{quantity}</Typography>
            </LabeledItem>
            <LabeledItem
              label={i18n('common:components.teaser.item.common.shop')}
            >
              <Chip label={shop} size="small" />
            </LabeledItem>
          </Box>
        </CardContent>
        <CardActions>
          <Button tabIndex={-1}>
            {i18n('common:components.teaser.item.common.action')}
          </Button>
        </CardActions>
      </Grid>
    </Card>
  )
}

interface CreateWishlistButtonProps extends ButtonProps {
  label: string
  wishlistTitle: string
}

const CreateWishlistButton: React.FC<CreateWishlistButtonProps> = ({
  label,
  wishlistTitle,
  ...props
}) => {
  const { themeMode } = useThemeMode()
  /*eslint @typescript-eslint/no-unused-vars: "off"*/
  const [i18n, { resolvedLanguage }] = useTranslation(['common'])
  const [loading, setLoading] = useState(false)
  const self = useGetSelf(false)
  const navigate = useNavigate()
  const escalate = useGlobalErrorHandler()
  const { setSession } = useSession()

  const theme = useMemo(() => {
    return themeMode === 'light' ? darkTheme : lightTheme
  }, [themeMode])

  const handleClick = async () => {
    setLoading(true)

    try {
      const user = self.value || (await postUsers())
      const wishlist = await postWishlists(
        user.session,
        wishlistTitle,
        resolvedLanguage || 'en',
      )

      mutateSelf(user)
      mutateWishlist(wishlist, user.session)

      setSession(user.session)

      setLoading(false)
      navigate(`/wishlist/${wishlist.reference}`)
    } catch (error) {
      setLoading(false)
      escalate(error)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <LoadingButton
        {...props}
        color="primary"
        fullWidth
        loading={loading}
        onClick={handleClick}
        variant="outlined"
        size="large"
        sx={{ ...props.sx, height: 50, maxWidth: 400 }}
      >
        {label}
      </LoadingButton>
    </ThemeProvider>
  )
}
