import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { translations as resources } from './translations'

const languageDetector = new LanguageDetector(null, {
  lookupLocalStorage: 'language',
  lookupQuerystring: 'language',
  order: ['querystring', 'localStorage', 'navigator'],
})

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    cleanCode: true,
    fallbackLng: 'en',
    ns: ['common', 'app'],
    returnEmptyString: false,
    resources,
  })
