import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import { AppBar, Box, IconButton, Toolbar, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { useGetSelf } from '../hooks/swr'
import { useThemeMode } from '../hooks/useThemeMode'
import { Logo } from './Logo'
import { RouterLink } from './RouterLink'

export const Header: React.FC = () => (
  <AppBar position="static" elevation={0}>
    <Toolbar sx={{ gap: 2 }}>
      <Box sx={{ display: 'flex', flex: 1 }}>
        <RouterLink color="inherit" display="flex" to="/" underline="none">
          <Logo />
        </RouterLink>
      </Box>
      <Actions />
    </Toolbar>
  </AppBar>
)

const ToggleThemeModeButton = () => {
  const theme = useTheme()
  const { toggleThemeMode } = useThemeMode()

  return (
    <IconButton
      aria-label="dark mode toggle"
      color="inherit"
      onClick={toggleThemeMode}
    >
      {theme.palette.mode === 'dark' ? (
        <Brightness7Icon />
      ) : (
        <Brightness4Icon />
      )}
    </IconButton>
  )
}

const Actions = () => {
  const self = useGetSelf(false)

  const profileButton = self.value && (
    <IconButton component={Link} color="inherit" to="/profile">
      <AccountCircleIcon />
    </IconButton>
  )

  return (
    <Box display="flex" gap={1}>
      <ToggleThemeModeButton />
      {profileButton}
    </Box>
  )
}
