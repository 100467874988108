import SendIcon from '@mui/icons-material/Send'
import { Box, TextField } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingIconButton } from '../components/LoadingIconButton'
import { useGlobalErrorHandler } from '../hooks/useGlobalErrorHandler'
import { LoginData } from '../../@types/app'
import { postSelfLogin } from '../utils/endpoints'
import { isEmail } from '../utils/validations'

export interface LoginFormProps {
  disabled: boolean
  onLoading: (loading: boolean) => void
  onComplete: (login: LoginData) => void
}

export const LoginForm: React.FC<LoginFormProps> = ({
  disabled,
  onLoading,
  onComplete,
}) => {
  const [i18n, { resolvedLanguage }] = useTranslation(['app'])
  const escalate = useGlobalErrorHandler()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault()
    setLoading(true)
    onLoading(true)

    try {
      const token = await postSelfLogin(email, resolvedLanguage || 'en')
      setLoading(false)
      onLoading(false)
      onComplete({ email: email.trim(), token })
    } catch (error) {
      setLoading(false)
      onLoading(false)
      escalate(error)
    }
  }

  return (
    <Box component="form" position="relative" onSubmit={handleSubmit}>
      <TextField
        autoComplete="email"
        disabled={disabled || loading}
        fullWidth
        label={i18n('app:pages.start.login.form.email.label')}
        onChange={(event) => setEmail(event.currentTarget.value)}
        placeholder={i18n('app:pages.start.login.form.email.placeholder')}
        size="small"
        type="email"
        value={email}
        inputProps={{ sx: { pr: 5 } }}
      />
      <Box position="absolute" right={0} top={0}>
        <LoadingIconButton
          color="primary"
          disabled={disabled || !isEmail(email.trim())}
          loading={loading}
          type="submit"
        >
          <SendIcon />
        </LoadingIconButton>
      </Box>
    </Box>
  )
}
