import { AlertProps } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditEmailDialog } from '../dialogs/EditEmailDialog'
import { UserData } from '../../@types/app'
import { EmailConfirmationAlert } from './EmailConfirmationAlert'
import { EmailReminderAlert } from './EmailReminderAlert'

export interface EmailAlertProps extends AlertProps {
  self: UserData
}

export const EmailAlert: React.FC<EmailAlertProps> = ({ self, ...props }) => {
  const [i18n] = useTranslation(['common'])
  const [showDialog, setShowDialog] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [token, setToken] = useState<string | null>(null)

  const dialog = showDialog && (
    <EditEmailDialog
      title={i18n('common:components.emailAlert.dialog.title')}
      session={self.session}
      onClose={() => setShowDialog(false)}
      onComplete={(token) => {
        setShowDialog(false)
        setShowConfirmation(true)
        setToken(token)
      }}
      open={showDialog}
    />
  )

  if (showConfirmation)
    return <EmailConfirmationAlert {...props} token={token} />
  else if (!self.email)
    return (
      <>
        {dialog}
        <EmailReminderAlert
          {...props}
          onClickAddEmail={() => setShowDialog(true)}
        />
      </>
    )
  else return <></>
}
