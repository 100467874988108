import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Page } from '../components/Page'
import { ResponsivePageTitle } from '../components/ResponsivePageTitle'
import { Root } from '../components/Root'
import { Teaser } from '../components/Teaser'
import { Testimonials } from '../components/Testimonials'
import { Why } from '../components/Why'
import TeaserChristmas from '../images/teaser-christmas.jpg'

export const IndexPage: React.FC = () => {
  const [i18n] = useTranslation(['common', 'app'])

  return (
    <Root
      title={i18n('app:pages.index.title')}
      description={i18n('app:pages.index.description')}
    >
      <Page disableGutter>
        <Teaser
          title={i18n('app:pages.index.teaser.title')}
          description={i18n('app:pages.index.teaser.description')}
          action={i18n('app:pages.index.teaser.action.create.label')}
          wishlistTitle={i18n('common:app.defaultWishlistTitle')}
        />
        <Container sx={{ py: 8 }}>
          <ResponsivePageTitle level={2} paragraph sx={{ textAlign: 'center' }}>
            {i18n('app:pages.index.what.title')}
          </ResponsivePageTitle>
          <Typography textAlign="center">
            {i18n('app:pages.index.what.description')}
          </Typography>
        </Container>
        <Container sx={{ py: 4 }}>
          <Why />
        </Container>
        <Container sx={{ py: 4 }}>
          <ResponsivePageTitle level={2} sx={{ mb: 8, textAlign: 'center' }}>
            {i18n('app:pages.index.more.title')}
          </ResponsivePageTitle>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardMedia
                  component="img"
                  image={TeaserChristmas}
                  alt={i18n('app:pages.index.more.christmas.image.alt')}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {i18n('app:pages.index.more.christmas.title')}
                  </Typography>
                  <Typography color="text.secondary">
                    {i18n('app:pages.index.more.christmas.description')}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button component={Link} to="/christmas">
                    {i18n('app:pages.index.more.action.label')}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Testimonials />
      </Page>
    </Root>
  )
}
