import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserData } from '../../@types/app'
import { LimitedTextField } from '../components/LimitedTextField'
import { useGlobalErrorHandler } from '../hooks/useGlobalErrorHandler'
import { useInputManager } from '../hooks/useInputManager'
import { putSelf } from '../utils/endpoints'
import { mutateSelf } from '../utils/mutations'
import {
  isError,
  isSuccess,
  Result,
  toError,
  toSuccess,
} from '../utils/validations'

export interface EditAccountFormProps {
  self: UserData
}

export const EditAccountForm: React.FC<EditAccountFormProps> = ({ self }) => {
  const [i18n] = useTranslation(['common', 'app'])
  const escalate = useGlobalErrorHandler()
  const [loading, setLoading] = useState(false)
  const name = useInputManager(self.name || '', validateName(i18n))

  const handleSubmit = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault()

    if (isSuccess(name.result)) {
      setLoading(true)

      try {
        const updatedSelf = await putSelf(self.session, {
          name: name.result.success,
        })
        setLoading(false)
        mutateSelf(updatedSelf)
      } catch (error) {
        setLoading(false)
        escalate(error)
      }
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <LimitedTextField
        disabled={loading}
        error={isError(name.result)}
        fullWidth
        label={i18n('app:forms.editAccount.name.label')}
        limit={80}
        onBlur={name.handleBlur}
        onChange={name.handleChange}
        helperText={i18n('app:forms.editAccount.name.hint')}
        value={name.value}
      />
      <Box mt={4} textAlign="right">
        <LoadingButton
          disabled={isError(name.result)}
          loading={loading}
          type="submit"
          variant="contained"
        >
          {i18n('common:app.save')}
        </LoadingButton>
      </Box>
    </Box>
  )
}

const validateName =
  (i18n: ReturnType<typeof useTranslation<['common']>>['t']) =>
  (name: string): Result<string | null> => {
    const result = name.trim()

    if (result.length === 0) return toSuccess(null)
    else if (result.length > 80)
      return toError(i18n('common:validations.text.maximum'))
    else return toSuccess(result)
  }
