import CodeIcon from '@mui/icons-material/Code'
import { Alert, AlertProps, AlertTitle, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'

interface DebugAlertProps extends AlertProps, PropsWithChildren {}

export const DebugAlert: React.FC<DebugAlertProps> = ({
  children,
  ...props
}) => (
  <Alert icon={<CodeIcon fontSize="inherit" />} severity="warning" {...props}>
    <AlertTitle>
      Debug hint
      <Typography variant="caption" component="p">
        Only visible in development environments
      </Typography>
    </AlertTitle>
    {children}
  </Alert>
)
