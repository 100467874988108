import { Masonry } from '@mui/lab'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  createTheme,
  ScopedCssBaseline,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material'
import React, { PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useThemeMode } from '../hooks/useThemeMode'
import AvatarAlenaLysenkina from '../images/avatar-alena-lysenkina.jpg'
import AvatarAnnaSmirnova from '../images/avatar-anna-smirnova.jpg'
import AvatarExoWire from '../images/avatar-exo-wire.png'
import AvatarLucasBarnes from '../images/avatar-lucas-barnes.jpg'
import AvatarMartinMovarek from '../images/avatar-martin-movarek.jpg'
import AvatarPolinaGollender from '../images/avatar-polina-gollender.jpg'
import AvatarTetianaShataieva from '../images/avatar-tetiana-shataieva.jpg'
import AvatarTimidel from '../images/avatar-timidel.jpg'
import AvatarZeng from '../images/avatar-zeng.jpg'
import BearPattern from '../images/bear-pattern.png'
import { darkTheme, lightTheme } from '../utils/themes'

export const Testimonials: React.FC = () => {
  const [i18n] = useTranslation(['common'])
  const theme = useTheme()
  const { themeMode } = useThemeMode()

  const tesitmonialsTheme = useMemo(() => {
    const background =
      themeMode === 'light'
        ? lightTheme.palette.primary.main
        : darkTheme.palette.primary.main

    return createTheme(darkTheme, {
      palette: {
        background: { default: background },
      },
    })
  }, [themeMode])

  return (
    <ThemeProvider theme={tesitmonialsTheme}>
      <ScopedCssBaseline
        component={Box}
        sx={{
          background: `url(${BearPattern})`,
          backgroundColor: theme.palette.primary.main,
          backgroundSize: '1000px',
          position: 'relative',
          '&::before': {
            background: `linear-gradient(180deg, ${theme.palette.primary.dark} 0%, rgba(0, 0, 0, 0) 90%)`,
            content: "''",
            height: 500,
            left: 0,
            pointerEvents: 'none',
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 0,
          },
          '&::after': {
            background: `linear-gradient(0deg, ${theme.palette.primary.light} 0%, rgba(0, 0, 0, 0) 90%)`,
            backgroundRepeat: 'none',
            bottom: 0,
            content: "''",
            height: 250,
            left: 0,
            pointerEvents: 'none',
            position: 'absolute',
            right: 0,
          },
        }}
      >
        <Container sx={{ pt: 8, position: 'relative' }}>
          <Typography component="h2" mb={8} textAlign="center" variant="h3">
            {i18n('common:components.testimonials.title')}
          </Typography>
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                maxHeight: 1000,
                overflow: 'hidden',
              }}
            >
              <Masonry
                columns={{ sx: 1, sm: 2, md: 3 }}
                spacing={3}
                sx={{ m: 0 }}
              >
                <Testimonial
                  avatar={AvatarMartinMovarek}
                  name="Martin Movarek"
                  handle="@minimalistphone"
                >
                  {i18n('common:components.testimonials.martin')}
                </Testimonial>
                <Testimonial
                  avatar={AvatarPolinaGollender}
                  name="Polina Gollender"
                  handle="@gollender"
                >
                  {i18n('common:components.testimonials.gollender')}
                </Testimonial>
                <Testimonial avatar={AvatarZeng} name="Zeng" handle="@zeng">
                  {i18n('common:components.testimonials.zeng')}
                </Testimonial>
                <Testimonial
                  avatar={AvatarTimidel}
                  name="Timidel"
                  handle="u/Timidel"
                >
                  {i18n('common:components.testimonials.timidel')}
                </Testimonial>
                <Testimonial
                  avatar={AvatarLucasBarnes}
                  name="Lucas Barnes"
                  handle="@lucas_barnes"
                >
                  {i18n('common:components.testimonials.lucas')}
                </Testimonial>
                <Testimonial
                  avatar={AvatarAlenaLysenkina}
                  name="Alena Lysenkina"
                  handle="@alena_lysenkina"
                >
                  {i18n('common:components.testimonials.alena')}
                </Testimonial>
                <Testimonial
                  avatar={AvatarTetianaShataieva}
                  name="Tetiana Shataieva"
                  handle="@tetiana_shataieva14"
                >
                  {i18n('common:components.testimonials.tetiana')}
                </Testimonial>
                <Testimonial
                  avatar={AvatarExoWire}
                  name="ExoWire"
                  handle="u/ExoWire"
                >
                  {i18n('common:components.testimonials.exowire')}
                </Testimonial>
                <Testimonial
                  avatar={AvatarAnnaSmirnova}
                  name="Anna Smirnova"
                  handle="@anna_smirnova6"
                >
                  {i18n('common:components.testimonials.anna')}
                </Testimonial>
              </Masonry>
            </Box>
          </ThemeProvider>
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  )
}

interface TestimonialProps extends PropsWithChildren {
  avatar: string
  name: string
  handle: string
}

const Testimonial: React.FC<TestimonialProps> = ({
  avatar,
  name,
  handle,
  children,
}) => {
  return (
    <Card>
      <CardHeader
        avatar={<Avatar src={avatar} />}
        title={name}
        subheader={handle}
      ></CardHeader>
      <CardContent sx={{ fontStyle: 'italic' }}>{children}</CardContent>
    </Card>
  )
}
