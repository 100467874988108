import { Alert, AlertProps, AlertTitle, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { selfUrl } from '../utils/endpoints'
import { DebugAlert } from './DebugAlert'

export interface EmailConfirmationAlertProps extends AlertProps {
  token: string | null
}

export const EmailConfirmationAlert: React.FC<EmailConfirmationAlertProps> = ({
  token,
  ...props
}) => {
  const [i18n] = useTranslation(['common'])

  return (
    <>
      <Alert {...props} severity="info">
        <AlertTitle>
          {i18n('common:components.emailConfirmationAlert.title')}
        </AlertTitle>
        {i18n('common:components.emailConfirmationAlert.description')}
      </Alert>
      {token && <TokenAlert token={token} />}
    </>
  )
}

interface TokenAlertProps {
  token: string
}

const TokenAlert: React.FC<TokenAlertProps> = ({ token }) => {
  const verifyUrl = selfUrl(`/verify?token=${token}`)

  return (
    <DebugAlert sx={{ mt: 2 }}>
      <Link href={verifyUrl}>{verifyUrl}</Link>
    </DebugAlert>
  )
}
