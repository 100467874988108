import { Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Page } from '../components/Page'
import { ResponsivePageTitle } from '../components/ResponsivePageTitle'
import { Root } from '../components/Root'
import { Teaser } from '../components/Teaser'
import { Testimonials } from '../components/Testimonials'
import { Why } from '../components/Why'

export const ChristmasPage: React.FC = () => {
  const [i18n] = useTranslation(['app'])

  return (
    <Root
      title={i18n('app:pages.christmas.title')}
      description={i18n('app:pages.christmas.description')}
    >
      <Page disableGutter>
        <Teaser
          title={i18n('app:pages.christmas.teaser.title')}
          description={i18n('app:pages.christmas.teaser.description')}
          action={i18n('app:pages.christmas.teaser.action.create.label')}
          wishlistTitle={i18n(
            'app:pages.christmas.teaser.action.create.wishlistTitle',
          )}
        />
        <Container sx={{ py: 8 }}>
          <ResponsivePageTitle level={1} paragraph sx={{ textAlign: 'center' }}>
            {i18n('app:pages.christmas.content.title')}
          </ResponsivePageTitle>
          {i18n('app:pages.christmas.content.description', {
            returnObjects: true,
          }).map((paragraph, index) => (
            <Typography key={index} textAlign="center" paragraph>
              {paragraph}
            </Typography>
          ))}
        </Container>
        <Container sx={{ py: 4 }}>
          <Why />
        </Container>
        <Testimonials />
      </Page>
    </Root>
  )
}
