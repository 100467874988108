import {
  SxProps,
  Theme,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { ReactNode } from 'react'

export interface ResponsivePageTitle extends TypographyProps {
  level: number
  paragraph?: boolean
  sx?: SxProps<Theme>
  children?: ReactNode
}

export const ResponsivePageTitle: React.FC<ResponsivePageTitle> = ({
  level,
  paragraph,
  sx,
  children,
}) => {
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Typography
      variant={variant(level, small)}
      component={component(level)}
      paragraph={paragraph}
      sx={{ ...sx, hyphens: 'auto' }}
    >
      {children}
    </Typography>
  )
}

const variant = (
  level: number,
  small: boolean,
): 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' => {
  if (level === 1) return small ? 'h3' : 'h2'
  else if (level === 2) return small ? 'h4' : 'h3'
  else if (level === 3) return small ? 'h5' : 'h4'
  else if (level === 4) return small ? 'h6' : 'h5'
  else return 'h6'
}

const component = (level: number): 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' => {
  if (level === 1) return 'h1'
  else if (level === 2) return 'h2'
  else if (level === 3) return 'h3'
  else if (level === 4) return 'h4'
  else if (level === 5) return 'h5'
  else return 'h6'
}
