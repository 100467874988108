import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContentText,
  DialogProps,
} from '@mui/material'
import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogImageContent } from '../components/DialogImageContent'
import { ResponsiveDialog } from '../components/ResponsiveDialog'
import { useGlobalErrorHandler } from '../hooks/useGlobalErrorHandler'
import BearDottedLine from '../images/bear-dotted-line.png'
import { deleteWishlistsReferenceItemsReferenceReservations } from '../utils/endpoints'
import { mutateWishlist } from '../utils/mutations'

export interface RemoveReservationDialogProps extends DialogProps {
  wishlistReference: string
  itemReference: string
  session: string
  onClose: () => void
}

export const RemoveReservationDialog: React.FC<
  RemoveReservationDialogProps
> = ({ wishlistReference, itemReference, session, onClose, ...props }) => {
  const [i18n] = useTranslation(['common', 'app'])
  const escalate = useGlobalErrorHandler()
  const [loading, setLoading] = useState(false)

  const handleClose = () => !loading && onClose()

  const handleSubmit = async (event: FormEvent<Element>) => {
    event.preventDefault()

    setLoading(true)

    try {
      const updatedWishlist =
        await deleteWishlistsReferenceItemsReferenceReservations(
          session,
          wishlistReference,
          itemReference,
        )
      setLoading(false)
      mutateWishlist(updatedWishlist, session)
      onClose()
    } catch (error) {
      setLoading(false)
      escalate(error)
    }
  }

  return (
    <ResponsiveDialog {...props} onClose={handleClose}>
      <DialogImageContent
        image={{
          alt: i18n('app:dialogs.removeReservation.image.alt'),
          url: BearDottedLine,
        }}
        title={i18n('app:dialogs.removeReservation.title')}
      >
        <DialogContentText paragraph>
          {i18n('app:dialogs.removeReservation.description1')}
        </DialogContentText>
        <DialogContentText>
          {i18n('app:dialogs.removeReservation.description2')}
        </DialogContentText>
      </DialogImageContent>
      <DialogActions>
        <Button color="inherit" disabled={loading} onClick={handleClose}>
          {i18n('common:app.cancel')}
        </Button>
        <LoadingButton loading={loading} onClick={handleSubmit}>
          {i18n('common:app.confirm')}
        </LoadingButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}
