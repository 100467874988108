import {
  Box,
  FormHelperText,
  OutlinedTextFieldProps,
  TextField,
} from '@mui/material'
import React, { ReactNode, useState } from 'react'

export interface LimitedTextFieldProps
  extends Omit<OutlinedTextFieldProps, 'variant'> {
  error?: boolean
  helperText?: ReactNode
  limit: number
}

export const LimitedTextField: React.FC<LimitedTextFieldProps> = ({
  error,
  helperText,
  limit,
  ...props
}) => {
  const [count, setCount] = useState(
    props.value ? ('' + props.value).length : 0,
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCount(event.currentTarget.value.length)
    props.onChange && props.onChange(event)
  }

  const hasError = error || count > limit

  return (
    <>
      <TextField {...props} error={hasError} onChange={handleChange} />
      <HelperText
        count={count}
        error={hasError}
        helperText={helperText}
        limit={limit}
      />
    </>
  )
}

interface HelperTextProps {
  count: number
  error: boolean
  limit: number
  helperText?: ReactNode
}

const HelperText: React.FC<HelperTextProps> = ({
  count,
  error,
  limit,
  helperText,
}) => {
  return (
    <FormHelperText
      error={error}
      component={Box}
      display="flex"
      sx={{ mx: 1.75 }}
    >
      <Box flex={1} mr={2}>
        {helperText}
      </Box>
      {`${count} / ${limit}`}
    </FormHelperText>
  )
}
