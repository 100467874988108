import SendIcon from '@mui/icons-material/Send'
import { Box, BoxProps, TextField } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArticleData, WishlistItemCreateData } from '../../@types/app'
import { LoadingIconButton } from '../components/LoadingIconButton'
import { EditItemDialog } from '../dialogs/EditItemDialog'
import { useGlobalErrorHandler } from '../hooks/useGlobalErrorHandler'
import { useNotification } from '../hooks/useGlobalMessage'
import {
  postExtractArticle,
  postWishlistsReferenceItems,
} from '../utils/endpoints'
import { mutateWishlist } from '../utils/mutations'
import { isUrl } from '../utils/validations'

const defaultValue =
  process.env.REACT_APP_ENVIRONMENT === 'development'
    ? 'https://www.hhv.de/shop/de/artikel/patta-ripstop-puffer-jacket-seneca-rock-926849'
    : ''

export interface CreateItemFormProps extends BoxProps {
  wishlistReference: string
  session: string
}

export const CreateItemForm: React.FC<CreateItemFormProps> = ({
  wishlistReference,
  session,
  ...props
}) => {
  const [i18n, { resolvedLanguage }] = useTranslation(['app'])
  const escalate = useGlobalErrorHandler()
  const [value, setValue] = useState(defaultValue)
  const [loading, setLoading] = useState(false)
  const [article, setArticle] = useState<ArticleData | null>(null)
  const [creating, setCreating] = useState(false)
  const notify = useNotification()

  const handleSubmitForm = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault()

    const result = value.trim()

    if (isUrl(result)) {
      setLoading(true)

      try {
        const article = await postExtractArticle(
          session,
          result,
          resolvedLanguage || 'en',
        )

        if (!article.name && !article.price && !article.image)
          notify(i18n('app:notification.articleExtraction'))

        setArticle({ ...article, url: article.url || result })
        setValue(defaultValue)
        setLoading(false)
      } catch (error) {
        setValue(defaultValue)
        setLoading(false)
        escalate(error)
      }
    } else {
      setArticle({ name: result, image: null, price: null, url: null })
      setValue(defaultValue)
    }
  }

  const handleSubmitItem = async (item: WishlistItemCreateData) => {
    setCreating(true)

    try {
      const updatedWishlist = await postWishlistsReferenceItems(
        session,
        wishlistReference,
        item,
      )
      mutateWishlist(updatedWishlist, session)
      setArticle(null)
      setCreating(false)
    } catch (error) {
      escalate(error)
      setCreating(false)
    }
  }

  const editDialog = article && (
    <EditItemDialog
      title={i18n('app:pages.wishlist.edit')}
      article={{ ...article, description: null, quantity: 1 }}
      loading={creating}
      onSubmit={handleSubmitItem}
      onClose={() => setArticle(null)}
      open={true}
    />
  )

  return (
    <>
      {editDialog}
      <Box
        {...props}
        component="form"
        position="relative"
        onSubmit={handleSubmitForm}
      >
        <TextField
          disabled={loading}
          fullWidth
          helperText={i18n('app:forms.createItem.hint')}
          label={i18n('app:forms.createItem.label')}
          onChange={(event) => setValue(event.currentTarget.value)}
          placeholder={i18n('app:forms.createItem.placeholder')}
          type="search"
          value={value}
          inputProps={{ sx: { pr: 7 } }}
        />
        <Box position="absolute" right={8} top={8}>
          <LoadingIconButton
            type="submit"
            color="primary"
            disabled={value.length === 0 || loading}
            loading={loading}
          >
            <SendIcon />
          </LoadingIconButton>
        </Box>
      </Box>
    </>
  )
}
