import {
  Box,
  BoxProps,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Language = {
  code: string
  flag: string
  country: string
  label: string
}

export const LanguageSelector: React.FC = () => {
  const [i18n, { changeLanguage, resolvedLanguage }] = useTranslation([
    'common',
  ])

  const languages: Language[] = i18n(
    'common:components.languageSelector.languages',
    { returnObjects: true },
  )

  const getLanguage = (code: string): Language => {
    const language = languages.find((language) =>
      code.startsWith(language.code),
    )

    if (!language) throw new Error(`No translations for language ${code}`)

    return language
  }

  const handleChange = (event: SelectChangeEvent) => {
    changeLanguage(event.target.value)
  }

  return (
    <FormControl size="small">
      <InputLabel>
        {i18n('common:components.languageSelector.label')}
      </InputLabel>
      <Select
        label={i18n('common:components.languageSelector.label')}
        onChange={handleChange}
        renderValue={(value) => {
          const language = getLanguage(value)
          return (
            <Item
              language={language}
              alt={i18n('common:components.languageSelector.alt', {
                country: language.country,
              })}
            />
          )
        }}
        value={resolvedLanguage}
        sx={{ width: 200 }}
      >
        {languages.map((language) => (
          <MenuItem key={language.code} value={language.code}>
            <Item
              language={language}
              alt={i18n('common:components.languageSelector.alt', {
                country: language.country,
              })}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

interface ItemProps extends BoxProps {
  language: Language
  alt: string
}

const Item: React.FC<ItemProps> = ({ language, alt, ...props }) => (
  <Box alignItems="center" display="flex" gap={2} {...props}>
    <img
      alt={alt}
      height={15}
      src={`https://flagcdn.com/${language.flag}.svg`}
      width={25}
    />
    {language.label}
  </Box>
)
