import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { Box, Breadcrumbs, BreadcrumbsProps, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouterLink } from './RouterLink'

export interface BreadcrumbNavigationProps extends BreadcrumbsProps {
  current: string | null
}

export const BreadcrumbNavigation: React.FC<BreadcrumbNavigationProps> = ({
  current,
  ...props
}) => {
  const [i18n] = useTranslation(['common'])

  const overview = (
    <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
      <FormatListBulletedIcon fontSize="inherit" sx={{ mr: 0.5, mt: '-2px' }} />
      {i18n('common:components.breadcrumbNavigation.overview.label')}
    </Box>
  )

  return (
    <Breadcrumbs {...props} aria-label="breadcrumb" separator="›">
      {current ? (
        <RouterLink color="inherit" to="/wishlists" underline="hover">
          {overview}
        </RouterLink>
      ) : (
        <Typography>{overview}</Typography>
      )}
      {current && <Typography>{current}</Typography>}
    </Breadcrumbs>
  )
}
