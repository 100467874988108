import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { UnexpectedResponseError } from '../utils/endpoints'
import { useNotification } from './useGlobalMessage'
import { useSession } from './useSession'

export type GlobalErrorHandlerContext = (error: unknown) => void

const globalErrorHandlerContext = createContext<GlobalErrorHandlerContext>(() =>
  console.error('escalate called on uninitialized context'),
)

export const useGlobalErrorHandler: () => GlobalErrorHandlerContext = () =>
  useContext(globalErrorHandlerContext)

export const GlobalErrorHandlerProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate()
  const notify = useNotification()
  const [i18n] = useTranslation(['common'])
  const { removeSession } = useSession()
  const [errors, setErrors] = useState<unknown[]>([])

  const escalate = (error: unknown) => setErrors([error])

  useEffect(() => {
    if (errors.length === 0) return

    const error = errors[0]

    if (error instanceof UnexpectedResponseError) {
      const status = error.response.status

      if (status === 401) {
        removeSession()
        notify(i18n('common:errors.sessionExpired'))
        navigate('/start', { replace: true })
      } else if (status === 422) notify(i18n('common:errors.inputRejected'))
      else {
        notify(i18n('common:errors.unknown'))
        console.log(error)
      }
    } else {
      if (!navigator.onLine) notify(i18n('common:errors.offline'))
      else if (error instanceof DOMException && error.name === 'AbortError') {
        notify(i18n('common:errors.timeout'))
      } else {
        notify(i18n('common:errors.unknown'))
        console.log(error)
      }
    }

    setErrors([])
  }, [errors.length])

  return (
    <globalErrorHandlerContext.Provider value={escalate}>
      {children}
    </globalErrorHandlerContext.Provider>
  )
}
