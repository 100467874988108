export type Success<A> = {
  success: A
  error?: never
}

export type Error = {
  success?: never
  error: string
}

export type Result<A> = Error | Success<A>

export function toSuccess<A>(value: A): Success<A> {
  return { success: value }
}

export function isSuccess<A>(result: Result<A>): result is Success<A> {
  return 'success' in result
}

export function toError(message: string): Error {
  return { error: message }
}

export function isError<A>(result: Result<A>): result is Error {
  return 'error' in result
}

export const isEmail: (value: string) => boolean = (value) =>
  /^.+@.+$/.test(value)

export const isUrl: (value: string) => boolean = (value) =>
  /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/.test(
    value,
  )
