import NoteAddIcon from '@mui/icons-material/NoteAdd'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Container,
  Grid,
  GridProps,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { UserData, WishlistSummaryData } from '../../@types/app'
import { BreadcrumbNavigation } from '../components/BreadcrumbNavigation'
import { EmailAlert } from '../components/EmailAlert'
import { EmptyStateHint } from '../components/EmptyStateHint'
import { Page } from '../components/Page'
import { ResponsivePageTitle } from '../components/ResponsivePageTitle'
import { Root } from '../components/Root'
import { EditWishlistDialog } from '../dialogs/EditWishlistDialog'
import { ShareWishlistDialog } from '../dialogs/ShareWishlistDialog'
import { useGetSelf, useGetWishlists } from '../hooks/swr'
import { useGlobalErrorHandler } from '../hooks/useGlobalErrorHandler'
import BearNerd from '../images/bear-nerd.png'
import { postWishlists } from '../utils/endpoints'
import { mutateWishlist } from '../utils/mutations'
import { hashCode, shuffle } from '../utils/randoms'

export const WishlistsPage: React.FC = () => {
  const [i18n] = useTranslation(['app'])
  const self = useGetSelf(true)
  const wishlists = useGetWishlists()

  return (
    <Root title={i18n('app:pages.wishlists.title')} description={null}>
      <Wishlists
        wishlists={wishlists.value || null}
        self={self.value || null}
      />
    </Root>
  )
}

interface WishlistsProps {
  wishlists: WishlistSummaryData[] | null
  self: UserData | null
}

const Wishlists: React.FC<WishlistsProps> = ({ wishlists, self }) => {
  const [i18n] = useTranslation()
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down('sm'))

  const emailReminder = self &&
    !self.email &&
    wishlists &&
    wishlists.length > 0 && <EmailAlert self={self} sx={{ mt: 4 }} />

  const items = () => {
    if (!self || !wishlists)
      return (
        <Grid container gap={2} mt={8}>
          {Array.from({ length: 3 }, (_, i) => i).map((i) => (
            <Grid item key={i} xs={12}>
              <Skeleton height={250} variant="rounded" />
            </Grid>
          ))}
        </Grid>
      )
    else if (wishlists.length === 0)
      return (
        <EmptyStateHint
          imageUrl={BearNerd}
          alt={i18n('app:pages.wishlists.empty.alt')}
          mt={small ? 6 : 18}
        >
          <Typography component="p" variant="h6">
            {i18n('app:pages.wishlists.empty.description')}
          </Typography>
        </EmptyStateHint>
      )
    else
      return (
        <Grid container gap={2} mt={8}>
          {wishlists.map((wishlist) => (
            <WishlistCard
              wishlist={wishlist}
              session={self.session}
              key={wishlist.reference}
            />
          ))}
        </Grid>
      )
  }

  return (
    <Page>
      <Container>
        <BreadcrumbNavigation current={null} />
        <Header session={self?.session || null} />
        {emailReminder}
        {items()}
      </Container>
    </Page>
  )
}

interface HeaderProps {
  session: string | null
}

const Header: React.FC<HeaderProps> = ({ session }) => {
  const [i18n] = useTranslation()

  if (!session)
    return (
      <Typography variant="h2" component="h1">
        <Skeleton />
      </Typography>
    )

  return (
    <>
      <ResponsivePageTitle level={1} sx={{ flex: 1 }}>
        {i18n('app:pages.wishlists.title')}
      </ResponsivePageTitle>
      <AddWishlistButton session={session} sx={{ mt: 2 }} />
    </>
  )
}

interface AddWishlistButtonProps extends LoadingButtonProps {
  session: string
}

const AddWishlistButton: React.FC<AddWishlistButtonProps> = ({
  session,
  ...props
}) => {
  const [i18n, { resolvedLanguage }] = useTranslation(['common', 'app'])
  const navigate = useNavigate()
  const escalate = useGlobalErrorHandler()
  const [loading, setLoading] = useState(false)

  const onClickAddWishlist = async () => {
    setLoading(true)

    try {
      const wishlist = await postWishlists(
        session,
        i18n('common:app.defaultWishlistTitle'),
        resolvedLanguage || 'en',
      )

      setLoading(false)
      mutateWishlist(wishlist, session)
      navigate(`/wishlist/${wishlist.reference}`)
    } catch (error) {
      setLoading(false)
      escalate(error)
    }
  }

  return (
    <LoadingButton
      {...props}
      color="primary"
      disabled={loading}
      onClick={onClickAddWishlist}
      startIcon={<NoteAddIcon />}
    >
      {i18n('app:pages.start.register.label')}
    </LoadingButton>
  )
}

interface WishlistCardProps extends GridProps {
  wishlist: WishlistSummaryData
  session: string
}

const WishlistCard: React.FC<WishlistCardProps> = ({
  wishlist,
  session,
  ...props
}) => {
  const [i18n] = useTranslation(['common'])
  const [showEditWishlistDialog, setShowEditWishlistDialog] = useState(false)
  const [showShareWishlistDialog, setShowShareWishlistDialog] = useState(false)

  return (
    <>
      <EditWishlistDialog
        session={session}
        wishlist={wishlist}
        onClose={() => setShowEditWishlistDialog(false)}
        open={showEditWishlistDialog}
      />
      <ShareWishlistDialog
        wishlistReference={wishlist.reference}
        onClose={() => setShowShareWishlistDialog(false)}
        open={showShareWishlistDialog}
      />
      <Grid item xs={12} {...props}>
        <Grid container component={Card}>
          <Grid item xs={12} md="auto">
            <WishlistImage
              images={wishlist?.previews}
              size={250}
              reference={wishlist.reference}
            />
          </Grid>
          <Box display="flex" flexDirection="column" flex={1}>
            <WishlistContent
              reference={wishlist.reference}
              title={wishlist.title}
              description={wishlist.description}
              numberOfItems={wishlist.items}
            />

            <CardActions>
              <Button
                color="primary"
                onClick={() => setShowEditWishlistDialog(true)}
              >
                {i18n('common:app.edit')}
              </Button>
              <Button
                color="inherit"
                onClick={() => setShowShareWishlistDialog(true)}
              >
                {i18n('common:app.share')}
              </Button>
            </CardActions>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

const WishlistImage: React.FC<{
  images: string[]
  size: number
  reference: string
}> = ({ images, size, reference }) => {
  const theme = useTheme()
  const medium = useMediaQuery(theme.breakpoints.down('md'))

  const colors = [
    theme.palette.grey[theme.palette.mode === 'dark' ? 900 : 50],
    theme.palette.grey[theme.palette.mode === 'dark' ? 800 : 100],
    theme.palette.grey[theme.palette.mode === 'dark' ? 700 : 200],
    theme.palette.grey[theme.palette.mode === 'dark' ? 600 : 300],
  ]

  const shuffledColors = shuffle(colors, hashCode(reference))

  const shuffledImages = shuffle(
    images.concat(Array(4).fill('')).slice(0, 4),
    hashCode(reference),
  )

  const getImage = (
    i: number,
    c: string,
    l?: number | string,
    r?: number | string,
    t?: number | string,
    b?: number | string,
  ) =>
    medium ? (
      <div
        style={{
          width: '25%',
          backgroundColor: c,
          aspectRatio: '1 / 1',
        }}
      >
        {shuffledImages[i] && (
          <img
            src={shuffledImages[i]}
            style={{
              display: 'block',
              height: '100%',
              objectFit: 'cover',
              width: '100%',
            }}
          />
        )}
      </div>
    ) : (
      <Box
        position="absolute"
        left={l}
        top={t}
        right={r}
        bottom={b}
        bgcolor={c}
      >
        {shuffledImages[i] && (
          <img
            src={shuffledImages[i]}
            style={{
              display: 'block',
              height: '100%',
              objectFit: 'cover',
              width: '100%',
            }}
          />
        )}
      </Box>
    )

  return (
    <CardActionArea
      component={Link}
      to={`/wishlist/${reference}`}
      sx={{ display: 'flex' }}
    >
      {medium ? (
        <Box display="flex" width="100%">
          {getImage(0, shuffledColors[0])}
          {getImage(1, shuffledColors[1])}
          {getImage(2, shuffledColors[2])}
          {getImage(3, shuffledColors[3])}
        </Box>
      ) : (
        <Box position="relative" width={size} height={size}>
          {getImage(0, shuffledColors[0], 0, '50%', 0, '50')}
          {getImage(1, shuffledColors[1], '50%', 0, 0, '50%')}
          {getImage(2, shuffledColors[2], 0, '50%', '50%', 0)}
          {getImage(3, shuffledColors[3], '50%', 0, '50%', 0)}
        </Box>
      )}
    </CardActionArea>
  )
}

interface WishlistContentProps {
  reference: string
  title: string
  description: string | null
  numberOfItems: number
}

const WishlistContent: React.FC<WishlistContentProps> = ({
  reference,
  title,
  description,
  numberOfItems,
}) => {
  const [i18n] = useTranslation(['app'])

  return (
    <CardActionArea
      component={Link}
      to={`/wishlist/${reference}`}
      sx={{ display: 'flex', flex: 1, width: '100%' }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          height: '100%',
          gap: 2,
        }}
      >
        <Box>
          <Typography variant="h4" component="h4" mb={1}>
            {title}
          </Typography>
          <Chip
            label={i18n('app:pages.wishlist.item.numberOfWishes.label', {
              count: numberOfItems,
            })}
            size="small"
          />
        </Box>
        <Box flex={1}>
          <Typography>{description}</Typography>
        </Box>
      </CardContent>
    </CardActionArea>
  )
}
