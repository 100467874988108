import { Box, BoxProps, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInputManager } from '../hooks/useInputManager'
import { isSuccess, Result, toError, toSuccess } from '../utils/validations'

export interface CreateReservationFormData {
  quantity: number
}

export interface CreateReservationFormProps extends BoxProps {
  disabled: boolean
  onComplete: (data: CreateReservationFormData | null) => void
}

export const CreateItemReservationForm: React.FC<
  CreateReservationFormProps
> = ({ disabled, onComplete, ...props }) => {
  const [i18n] = useTranslation(['common', 'app'])
  const quantity = useInputManager('1', validateQuantity(i18n))

  useEffect(() => {
    const data = isSuccess(quantity.result)
      ? { quantity: quantity.result.success }
      : null

    onComplete(data)
  }, [quantity.value])

  return (
    <Box textAlign="center" {...props}>
      <TextField
        disabled={disabled}
        error={quantity.hasError}
        helperText={quantity.error || ' '}
        label={i18n('app:forms.createReservation.quantity.label')}
        onBlur={quantity.handleBlur}
        onChange={quantity.handleChange}
        value={quantity.value}
        inputProps={{ sx: { textAlign: 'center' } }}
        sx={{ width: 150 }}
      />
    </Box>
  )
}

const validateQuantity =
  (i18n: ReturnType<typeof useTranslation<['common']>>['t']) =>
  (value: string): Result<number> => {
    const result = value.trim()

    if (result.length === 0)
      return toError(i18n('common:validations.text.required'))
    else {
      const number = parseInt(result)

      if (isNaN(number))
        return toError(i18n('common:validations.number.parsing'))
      else if (number < 1)
        return toError(i18n('common:validations.number.minimum'))
      else if (number > 1000)
        return toError(i18n('common:validations.number.maximum'))
      else return toSuccess(number)
    }
  }
