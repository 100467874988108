import { Button, Snackbar, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/system'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

export type NotificationContext = (message: string) => void

const notificationContext = createContext<NotificationContext>(() =>
  console.error('notify called on uninitialized context'),
)

export const useNotification: () => NotificationContext = () =>
  useContext(notificationContext)

export const NotificationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [message, setMessage] = useState<string | null>(null)
  const [i18n] = useTranslation(['common'])
  const notify = (message: string) => setMessage(message)

  const theme = useTheme()

  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <notificationContext.Provider value={notify}>
      <Snackbar
        anchorOrigin={{
          vertical: lessThanSmall ? 'top' : 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={10 * 1000}
        message={message}
        onClose={() => setMessage(null)}
        open={message !== null}
        sx={{ maxWidth: 600 }}
        action={
          <Button color="inherit" size="small" onClick={() => setMessage(null)}>
            {i18n('common:app.close')}
          </Button>
        }
      />
      {children}
    </notificationContext.Provider>
  )
}
