import {
  ArticleData,
  ReservationCreateData,
  UserData,
  UserUpdateData,
  WishlistData,
  WishlistItemCreateData,
  WishlistUpdateData,
} from '../../@types/app'

export const apiUrl = (path: string): string =>
  `${process.env.REACT_APP_API_URL}${path}`
export const selfUrl = (path: string): string =>
  `${process.env.REACT_APP_BASE_URL}${path}`

export class UnexpectedResponseError extends Error {
  constructor(public response: Response) {
    super(`Unexpected response ${response.status}`)
  }
}

export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE'

export const unauthorizedFetch = (
  method: Method,
  path: string,
  body?: BodyInit,
  init?: RequestInit,
  timeout?: number,
): Promise<Response> => {
  const controller = new AbortController()
  const abort = setTimeout(() => controller.abort(), (timeout || 10) * 1000)

  return fetch(apiUrl(path), {
    method,
    body,
    signal: controller.signal,
    ...init,
    headers: {
      ...(body && !(body instanceof FormData)
        ? { 'Content-Type': 'application/json' }
        : {}),
      ...init?.headers,
    },
  }).then((response) => {
    clearTimeout(abort)
    return response
  })
}

export const authorizedFetch = (
  method: Method,
  path: string,
  session: string,
  body?: BodyInit,
  init?: RequestInit,
  timeout?: number,
): Promise<Response> =>
  unauthorizedFetch(
    method,
    path,
    body,
    {
      ...init,
      headers: {
        Authorization: `Bearer ${session}`,
        ...init?.headers,
      },
    },
    timeout,
  )

export const deleteWishlistsReference = (
  session: string,
  wishlistReference: string,
): Promise<void> =>
  authorizedFetch('DELETE', `/wishlists/${wishlistReference}`, session).then(
    (response) => {
      if (response.status === 204) return
      else throw new UnexpectedResponseError(response)
    },
  )

export const deleteWishlistsReferenceItemsReference = (
  session: string,
  wishlistReference: string,
  itemReference: string,
): Promise<WishlistData> =>
  authorizedFetch(
    'DELETE',
    `/wishlists/${wishlistReference}/items/${itemReference}`,
    session,
  ).then((response) => {
    if (response.status === 200) return response.json()
    else throw new UnexpectedResponseError(response)
  })

export const postSelfLogin = (
  email: string,
  language: string,
): Promise<string | null> =>
  unauthorizedFetch('POST', '/self/login', JSON.stringify({ email }), {
    headers: { 'Accept-Language': language },
  }).then((response) => {
    if (response.status === 204) return null
    else if (response.status === 200) return response.json()
    else throw new UnexpectedResponseError(response)
  })

export const postExtractArticle = (
  session: string,
  url: string,
  language: string,
): Promise<ArticleData> =>
  authorizedFetch(
    'POST',
    '/extract-article',
    session,
    JSON.stringify({ url }),
    { headers: { 'Accept-Language': language } },
  ).then((response) => {
    if (response.status === 200) return response.json()
    else throw new UnexpectedResponseError(response)
  })

export const postUsers = (): Promise<UserData> =>
  unauthorizedFetch('POST', '/users').then((response) => {
    if (response.status === 201) return response.json()
    else throw new UnexpectedResponseError(response)
  })

export const postWishlists = (
  session: string,
  title: string,
  language: string,
): Promise<WishlistData> =>
  authorizedFetch('POST', '/wishlists', session, JSON.stringify({ title }), {
    headers: {
      'Accept-Language': language,
    },
  }).then((response) => {
    if (response.status === 201) return response.json()
    else throw new UnexpectedResponseError(response)
  })

export const postWishlistsReferenceItems = (
  session: string,
  wishlistReference: string,
  item: WishlistItemCreateData,
): Promise<WishlistData> => {
  const formData = new FormData()

  formData.append(
    'data',
    JSON.stringify({
      ...item,
      image: typeof item.image === 'string' ? item.image : null,
    }),
  )

  if (item.image instanceof File) {
    formData.append('image', item.image)
  }

  return authorizedFetch(
    'POST',
    `/wishlists/${wishlistReference}/items`,
    session,
    formData,
    undefined,
    item.image instanceof File ? 30 : undefined,
  ).then((response) => {
    if (response.status === 201) return response.json()
    else throw new UnexpectedResponseError(response)
  })
}

export const postSelfVerifyToken = (token: string): Promise<UserData> =>
  unauthorizedFetch('POST', `/self/verify/${token}`).then((response) => {
    if (response.status === 200) return response.json()
    else throw new UnexpectedResponseError(response)
  })

export const putSelf = (
  session: string,
  user: UserUpdateData,
): Promise<UserData> =>
  authorizedFetch('PUT', '/self', session, JSON.stringify(user)).then(
    (response) => {
      if (response.status === 200) return response.json()
      else throw new UnexpectedResponseError(response)
    },
  )

export const putSelfEmail = (
  session: string,
  email: string,
  language: string,
): Promise<string | null> =>
  authorizedFetch('PUT', '/self/email', session, JSON.stringify({ email }), {
    headers: { 'Accept-Language': language },
  }).then((response) => {
    if (response.status === 204) return null
    else if (response.status === 200) return response.json()
    else throw new UnexpectedResponseError(response)
  })

export const putWishlistsReference = (
  session: string,
  wishlistReference: string,
  wishlist: WishlistUpdateData,
): Promise<WishlistData> =>
  authorizedFetch(
    'PUT',
    `/wishlists/${wishlistReference}`,
    session,
    JSON.stringify(wishlist),
  ).then((response) => {
    if (response.status === 200) return response.json()
    else throw new UnexpectedResponseError(response)
  })

export const putWishlistsReferenceItems = (
  session: string,
  wishlistReference: string,
  items: string[],
): Promise<WishlistData> =>
  authorizedFetch(
    'PUT',
    `/wishlists/${wishlistReference}/items`,
    session,
    JSON.stringify({ items }),
  ).then((response) => {
    if (response.status === 200) return response.json()
    else throw new UnexpectedResponseError(response)
  })

export const putWishlistsReferenceItemsReference = (
  session: string,
  wishlistReference: string,
  itemReference: string,
  item: WishlistItemCreateData,
): Promise<WishlistData> => {
  const formData = new FormData()

  formData.append(
    'data',
    JSON.stringify({
      ...item,
      image: typeof item.image === 'string' ? item.image : null,
    }),
  )

  if (item.image instanceof File) {
    formData.append('image', item.image)
  }

  return authorizedFetch(
    'PUT',
    `/wishlists/${wishlistReference}/items/${itemReference}`,
    session,
    formData,
    undefined,
    item.image instanceof File ? 30 : undefined,
  ).then((response) => {
    if (response.status === 200) return response.json()
    else throw new UnexpectedResponseError(response)
  })
}

export const postWishlistsReferenceItemsReferenceReservations = (
  session: string,
  wishlistReference: string,
  itemReference: string,
  reservation: ReservationCreateData,
): Promise<WishlistData> =>
  authorizedFetch(
    'POST',
    `/wishlists/${wishlistReference}/items/${itemReference}/reservations`,
    session,
    JSON.stringify(reservation),
  ).then((response) => {
    if (response.status === 200) return response.json()
    else throw new UnexpectedResponseError(response)
  })

export const deleteWishlistsReferenceItemsReferenceReservations = (
  session: string,
  wishlistReference: string,
  itemReference: string,
): Promise<WishlistData> =>
  authorizedFetch(
    'DELETE',
    `/wishlists/${wishlistReference}/items/${itemReference}/reservations`,
    session,
  ).then((response) => {
    if (response.status === 200) return response.json()
    else throw new UnexpectedResponseError(response)
  })
