import { PropsWithChildren, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { translations } from '../translations'

export interface RootProps extends PropsWithChildren {
  title: string | null
  description: string | null
}

export const Root: React.FC<RootProps> = ({ title, description, children }) => {
  const [i18n, { resolvedLanguage }] = useTranslation(['common', 'app'])

  const languages = Object.keys(translations)

  const documentTitle = title
    ? `${title} | ${i18n('common:app.name')}`
    : i18n('common:app.name')

  useEffect(() => {
    document.title = documentTitle
    document.head
      .querySelector('meta[property="og:title"]')
      ?.setAttribute('content', documentTitle)

    document.head
      .querySelector('meta[name="description"]')
      ?.setAttribute('content', description || '')
    document.head
      .querySelector('meta[property="og:description"]')
      ?.setAttribute('content', description || '')
  }, [title, description])

  useEffect(() => {
    if (resolvedLanguage !== undefined)
      document.documentElement.lang = resolvedLanguage

    document.head
      .querySelectorAll('link[rel="alternate"]')
      .forEach((element) => element.remove())

    languages.forEach((language) => {
      const url = new URL(window.location.href)

      if (language !== resolvedLanguage) {
        url.searchParams.set('language', language)
        url.searchParams.delete('ref')

        const link = document.createElement('link')
        link.setAttribute('rel', 'alternate')
        link.setAttribute('hreflang', language)
        link.setAttribute('href', url.toString())
        document.head.appendChild(link)
      }
    })
  }, [resolvedLanguage])

  return <>{children}</>
}
