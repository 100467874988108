import { createTheme, PaletteMode } from '@mui/material'
import { grey } from '@mui/material/colors'

const pink = '#E56399'
const purple = '#5A3596'

const createWishbearTheme = (
  mode: PaletteMode,
  primary: string,
  background: string,
) =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: primary,
      },
      background: {
        default: background,
      },
    },
    typography: {
      fontFamily: ['Fira Sans', 'sans-serif'].join(','),
    },
  })

export const lightTheme = createWishbearTheme('light', purple, grey[50])
export const darkTheme = createWishbearTheme('dark', pink, grey[900])
