import {
  Box,
  CircularProgress,
  IconButton,
  IconButtonProps,
} from '@mui/material'

interface LoadingIconButtonProps extends IconButtonProps {
  loading: boolean
}

export const LoadingIconButton: React.FC<LoadingIconButtonProps> = ({
  loading,
  ...props
}) => {
  if (loading) {
    return (
      <Box p={1} sx={{ height: 40, width: 40 }}>
        <CircularProgress size={24} />
      </Box>
    )
  } else {
    return <IconButton {...props} />
  }
}
