import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormDialog } from '../components/FormDialog'
import { EditItemForm } from '../forms/EditItemForm'
import { ArticleData, WishlistItemCreateData } from '../../@types/app'

interface EditItemDialogProps extends Omit<DialogProps, 'onSubmit'> {
  title: string
  article: ArticleData & {
    description: string | null
    quantity: number | null
    url: string | null
  }
  loading: boolean
  onSubmit: (item: WishlistItemCreateData) => void
  onClose: () => void
}

export const EditItemDialog: React.FC<EditItemDialogProps> = ({
  title,
  article,
  loading,
  onSubmit,
  onClose,
  ...props
}) => {
  const [i18n] = useTranslation(['common', 'app'])
  const [item, setItem] = useState<WishlistItemCreateData | null>(null)

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (item) onSubmit(item)
  }

  const handleClose = () => {
    if (!loading) onClose()
  }

  return (
    <FormDialog
      {...props}
      fullWidth
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <EditItemForm
          data={{
            ...article,
            title: article.name,
            quantity: article.quantity,
          }}
          loading={loading}
          onUpdate={setItem}
        />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={loading} onClick={handleClose}>
          {i18n('common:app.cancel')}
        </Button>
        <LoadingButton disabled={item === null} loading={loading} type="submit">
          {i18n('common:app.save')}
        </LoadingButton>
      </DialogActions>
    </FormDialog>
  )
}
