import { Box, CardContent, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Root } from '../components/Root'
import { Splash } from '../components/Splash'
import BearSeeNoEvil from '../images/bear-see-no-evil.png'
import { selfUrl } from '../utils/endpoints'

export const NotFoundPage: React.FC = () => {
  const [i18n] = useTranslation(['app'])

  return (
    <Root title={i18n('app:pages.notFound.title')} description={null}>
      <Splash>
        <CardContent sx={{ p: 4 }}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={BearSeeNoEvil} style={{ height: 100 }} />
          </Box>
          <Typography
            align="center"
            component="h1"
            variant="h5"
            mt={2}
            paragraph
          >
            {i18n('app:pages.notFound.title')}
          </Typography>
          <Typography align="center">
            {i18n('app:pages.notFound.description')}
          </Typography>
          <Box mt={4} textAlign="center">
            <Link href={selfUrl('/')}>{i18n('app:pages.notFound.home')}</Link>
          </Box>
        </CardContent>
      </Splash>
    </Root>
  )
}
