import { Backdrop, CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Root } from '../components/Root'
import { Splash } from '../components/Splash'
import { useGlobalErrorHandler } from '../hooks/useGlobalErrorHandler'
import { useSession } from '../hooks/useSession'
import { postSelfVerifyToken } from '../utils/endpoints'
import { mutateSelf } from '../utils/mutations'

export const VerifyPage: React.FC = () => {
  const navigate = useNavigate()
  const escalate = useGlobalErrorHandler()
  const { setSession } = useSession()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  useEffect(() => {
    if (token) {
      postSelfVerifyToken(token)
        .then((self) => {
          mutateSelf(self)
          setSession(self.session)
          navigate('/start')
        })
        .catch((error) => {
          navigate('/start')
          escalate(error)
        })
    } else {
      navigate('/start')
    }
  }, [])

  return (
    <Root title={null} description={null}>
      <Splash>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Splash>
    </Root>
  )
}
