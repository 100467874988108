import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { Box, BoxProps, Button, Typography } from '@mui/material'
import React, { MouseEvent, useState } from 'react'
import '../i18n'
import { useTranslation } from 'react-i18next'

export interface ConfirmationButtonProps extends LoadingButtonProps {
  condition?: boolean
  ConfirmationBoxProps?: BoxProps
}

export const ConfirmationButton: React.FC<ConfirmationButtonProps> = ({
  condition = true,
  ConfirmationBoxProps,
  children,
  ...props
}) => {
  const [i18n] = useTranslation(['common', 'app'])
  const [confirmation, setConfirmation] = useState(false)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (confirmation || !condition) props.onClick && props.onClick(event)
    else setConfirmation(true)
  }

  if (confirmation && !props.loading)
    return (
      <Box alignItems="center" display="flex" gap={1} {...ConfirmationBoxProps}>
        <Typography>
          {i18n('common:components.confirmationButton.confirm')}
        </Typography>
        <Button
          color={props.color}
          onClick={handleClick}
          sx={{ minWidth: 'auto' }}
        >
          {i18n('common:app.yes')}
        </Button>
        <Button
          color="inherit"
          onClick={() => setConfirmation(false)}
          sx={{ minWidth: 'auto' }}
        >
          {i18n('common:app.no')}
        </Button>
      </Box>
    )
  else
    return (
      <LoadingButton {...props} onClick={handleClick}>
        {children}
      </LoadingButton>
    )
}
