import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSession } from '../hooks/useSession'
import { Root } from '../components/Root'

export const LoginPage: React.FC = () => {
  const { setSession, removeSession } = useSession()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const session = searchParams.get('session')

  useEffect(() => {
    if (session === null) {
      removeSession()
    } else {
      setSession(session)
    }

    navigate('/start')
  }, [])

  return <Root title={null} description={null} />
}
