const random = (seed: number): number => {
  const x = Math.sin(seed++) * 10000
  return x - Math.floor(x)
}

export const shuffle = <A>(array: A[], seed: number): A[] => {
  let m = array.length,
    t: A,
    i: number

  while (m) {
    i = Math.floor(random(seed) * m--)

    t = array[m]
    array[m] = array[i]
    array[i] = t
    ++seed
  }

  return array
}

export const hashCode = (value: string): number => {
  let hash = 0,
    i: number,
    chr: number
  if (value.length === 0) return hash
  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }
  return hash
}
