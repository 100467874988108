import { DialogProps, Paper } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { ResponsiveDialog } from './ResponsiveDialog'

export interface FormDialogProps extends Omit<DialogProps, 'onSubmit'> {
  onSubmit: (event: React.FormEvent) => void
}

export const FormDialog: React.FC<FormDialogProps> = ({
  onSubmit,
  ...props
}) => {
  return (
    <ResponsiveDialog
      {...props}
      open={props.open}
      onSubmit={onSubmit}
      PaperProps={
        /*eslint @typescript-eslint/ban-ts-comment: "off"*/
        // @ts-ignore
        { component: 'form' }
      }
    ></ResponsiveDialog>
  )
}

export const FormPaper: React.FC<PropsWithChildren> = ({ children }) => (
  <Paper>{children}</Paper>
)
