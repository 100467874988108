import { Box, DialogContent, Typography } from '@mui/material'
import React, { PropsWithChildren, ReactNode } from 'react'

export interface DialogImageContentProps extends PropsWithChildren {
  image: {
    alt: string
    url: string
  }
  title: ReactNode
}

export const DialogImageContent: React.FC<DialogImageContentProps> = ({
  image,
  title,
  children,
}) => {
  return (
    <DialogContent>
      <Box textAlign="center">
        <img
          alt={image.alt}
          src={image.url}
          style={{ height: 150, width: 150 }}
        />
        <Typography variant="h4" component="h3" mb={4} mt={2}>
          {title}
        </Typography>
      </Box>
      {children}
    </DialogContent>
  )
}
