import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

export interface SessionContext {
  session: string | null
  setSession: (session: string) => void
  removeSession: () => void
}

const sessionContext = createContext<SessionContext>({
  session: null,
  setSession: () => {
    console.error('setSession called on uninitialized context')
  },
  removeSession: () => {
    console.error('removeSession called on uninitialized context')
  },
})

export const useSession: () => SessionContext = () => useContext(sessionContext)

export const SessionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<string | null>(
    localStorage.getItem('session'),
  )

  const value: SessionContext = {
    session: state,
    setSession: (session) => {
      localStorage.setItem('session', session)
      setState(session)
    },
    removeSession: () => {
      localStorage.removeItem('session')
      setState(null)
    },
  }

  return (
    <sessionContext.Provider value={value}>{children}</sessionContext.Provider>
  )
}
