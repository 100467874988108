import DeleteIcon from '@mui/icons-material/Delete'
import UploadIcon from '@mui/icons-material/Upload'
import {
  Box,
  ButtonBase,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WishlistItemCreateData } from '../../@types/app'
import { LimitedTextField } from '../components/LimitedTextField'
import { useInputManager } from '../hooks/useInputManager'
import ImagePlaceholder from '../images/image-placeholder.svg'
import {
  isSuccess,
  isUrl,
  Result,
  toError,
  toSuccess,
} from '../utils/validations'

export interface EditItemFormData {
  title: string | null
  image: File | string | null
  description: string | null
  price: string | null
  quantity: number | null
  url: string | null
}

export interface EditItemFormProps {
  data: EditItemFormData
  loading: boolean
  onUpdate: (item: WishlistItemCreateData | null) => void
}

export const EditItemForm: React.FC<EditItemFormProps> = ({
  data,
  loading,
  onUpdate,
}) => {
  const [i18n] = useTranslation(['common', 'app'])
  const title = useInputManager(data.title, validateTitle(i18n))
  const description = useInputManager(
    data.description,
    validateDescription(i18n),
  )
  const price = useInputManager(data.price || null, validatePrice(i18n))
  const url = useInputManager(data.url, validateUrl(i18n))
  const [quantity, setQuantity] = useState<Result<number | null>>(
    toSuccess(data.quantity),
  )
  const [image, setImage] = useState<string | null>(
    typeof data.image === 'string' ? data.image : null,
  )
  const [file, setFile] = useState<File | null>(
    data.image instanceof File ? data.image : null,
  )
  const [preview, setPreview] = useState<string | null>(null)

  const previewOrImage = preview || image

  const handleImageUploadChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.currentTarget.files
    if (!files || files.length === 0) return
    setFile(files[0])
  }

  useEffect(() => {
    const data =
      isSuccess(title.result) &&
      isSuccess(description.result) &&
      isSuccess(price.result) &&
      isSuccess(quantity) &&
      isSuccess(url.result)
        ? {
            title: title.result.success,
            image: file || image,
            description: description.result.success,
            price: price.result.success,
            quantity: quantity.success,
            url: url.result.success,
          }
        : null

    onUpdate(data)
  }, [
    title.value,
    description.value,
    price.value,
    quantity.success,
    url.value,
    image,
    file,
  ])

  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file)
      setPreview(url)
      return () => URL.revokeObjectURL(url)
    } else setPreview(null)
  }, [file])

  return (
    <>
      <Box
        bgcolor={(theme) => theme.palette.background.default}
        borderRadius={1}
        height={250}
        mb={4}
        position="relative"
        textAlign="center"
        sx={{
          backgroundImage: `url(${ImagePlaceholder})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '60% 60%',
        }}
      >
        {previewOrImage && (
          <img
            src={previewOrImage}
            alt={i18n('app:forms.editItem.image.alt')}
            style={{ display: 'inline-block', height: '100%', maxWidth: 250 }}
          />
        )}
        <Box bottom={4} display="flex" gap={0.5} position="absolute" right={4}>
          <IconButton
            aria-label="upload picture"
            component="label"
            disabled={loading}
            title={i18n('app:forms.editItem.image.upload.title')}
          >
            <input
              accept="image/*"
              hidden
              onChange={handleImageUploadChange}
              type="file"
            />
            <UploadIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            disabled={loading || (image === null && preview === null)}
            onClick={() => {
              setImage(null)
              setFile(null)
            }}
            title={i18n('app:forms.editItem.image.delete.title')}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      <Grid container gap={2}>
        <Grid item xs={12}>
          <LimitedTextField
            disabled={loading}
            error={title.hasError}
            fullWidth
            helperText={title.error}
            label={i18n('app:forms.editItem.title.label')}
            limit={120}
            onBlur={title.handleBlur}
            onChange={title.handleChange}
            placeholder={i18n('app:forms.editItem.title.placeholder')}
            required
            value={title.value}
          />
        </Grid>
        <Grid item xs={12}>
          <LimitedTextField
            disabled={loading}
            error={description.hasError}
            fullWidth
            helperText={description.error}
            label={i18n('app:forms.editItem.description.label')}
            limit={250}
            multiline
            onBlur={description.handleBlur}
            onChange={description.handleChange}
            rows={5}
            value={description.value}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container gap={2}>
            <Grid item xs>
              <TextField
                disabled={loading}
                error={price.hasError}
                fullWidth
                helperText={price.error || ' '}
                label={i18n('app:forms.editItem.price.label')}
                onBlur={price.handleBlur}
                onChange={price.handleChange}
                placeholder={i18n('app:forms.editItem.price.placeholder')}
                value={price.value}
              />
            </Grid>
            <Grid item>
              <QuantityToggle
                initial={data.quantity}
                disabled={loading}
                onUpdate={setQuantity}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={loading}
            error={url.hasError}
            fullWidth
            helperText={url.error || ' '}
            label={i18n('app:forms.editItem.url.label')}
            onBlur={url.handleBlur}
            onChange={url.handleChange}
            placeholder={i18n('app:forms.editItem.url.placeholder')}
            value={url.value}
          />
        </Grid>
      </Grid>
    </>
  )
}

export interface QuantityToggleProps {
  initial: number | null
  disabled: boolean
  onUpdate: (result: Result<number | null>) => void
}

export const QuantityToggle: React.FC<QuantityToggleProps> = ({
  initial,
  disabled,
  onUpdate,
}) => {
  const theme = useTheme()
  const [i18n] = useTranslation(['common', 'app'])
  const value = useInputManager(
    initial ? '' + initial : '1',
    validateQuantity(i18n),
  )
  const [toggled, setToggled] = useState(initial === null)

  const defaultColor =
    theme.palette.grey[theme.palette.mode === 'dark' ? 700 : 400]
  const highlightColor =
    theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 900]
  const selectionColor =
    theme.palette.grey[theme.palette.mode === 'dark' ? 800 : 100]

  const error = toggled ? null : value.error

  useEffect(() => {
    if (toggled) {
      onUpdate(toSuccess(null))
    } else onUpdate(value.result)
  }, [value.value, toggled])

  return (
    <Box>
      <Box display="flex">
        <TextField
          disabled={disabled}
          error={error !== null}
          inputProps={{ sx: { textAlign: 'center' } }}
          label={i18n('app:forms.editItem.quantity.label')}
          onFocus={() => setToggled(false)}
          onBlur={value.handleBlur}
          onChange={value.handleChange}
          value={value.value}
          sx={{
            backgroundColor: toggled ? 'transparent' : selectionColor,
            width: 80,
            '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                top: -4,
              },
            '& .MuiOutlinedInput-notchedOutline': {
              borderBottomRightRadius: 0,
              borderColor: toggled ? defaultColor : highlightColor,
              borderTopRightRadius: 0,
              borderWidth: toggled ? 1 : 2,
              top: toggled ? -5 : -4,
              zIndex: toggled ? 0 : 1,
            },
            '& .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
              {
                borderWidth: 1,
                top: -5,
              },
          }}
        />
        <ButtonBase
          disabled={disabled}
          onClick={() => setToggled(true)}
          sx={{
            backgroundColor: toggled ? selectionColor : 'transparent',
            border: 'none',
            boxSizing: 'border-box',
            color: toggled
              ? theme.palette.text.primary
              : theme.palette.text.secondary,
            height: '56px',
            ml: '-1px',
            position: 'relative',
            px: 2,
            '&:hover': {
              borderColor: highlightColor,
            },
            '&::after': {
              borderWidth: toggled ? 2 : 1,
              borderStyle: 'solid',
              borderColor: toggled ? highlightColor : defaultColor,
              borderRadius: 1,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              bottom: 0,
              content: "''",
              left: 0,
              position: 'absolute',
              right: 0,
              top: 0,
            },
          }}
        >
          <Typography variant="button">
            {i18n('app:forms.editItem.quantity.unlimited')}
          </Typography>
        </ButtonBase>
      </Box>
      <FormHelperText error sx={{ mx: '14px' }}>
        {error}
      </FormHelperText>
    </Box>
  )
}

const validateTitle =
  (i18n: ReturnType<typeof useTranslation<['common']>>['t']) =>
  (title: string): Result<string> => {
    const result = title.trim()
    const length = result.length
    if (length === 0) return toError(i18n('common:validations.text.required'))
    else if (length > 120)
      return toError(i18n('common:validations.text.maximum'))
    else return toSuccess(result)
  }

const validateDescription =
  (i18n: ReturnType<typeof useTranslation<['common']>>['t']) =>
  (description: string): Result<string | null> => {
    const result = description.trim()
    const length = result.length
    if (length > 250) return toError(i18n('common:validations.text.maximum'))
    else return toSuccess(length === 0 ? null : result)
  }

const validatePrice =
  (i18n: ReturnType<typeof useTranslation<['common']>>['t']) =>
  (price: string): Result<string | null> => {
    const result = price.trim()

    if (result.length === 0) return toSuccess(null)
    else if (result.length > 20)
      return toError(i18n('common:validations.text.maximum'))
    else return toSuccess(result)
  }

const validateQuantity =
  (i18n: ReturnType<typeof useTranslation<['common']>>['t']) =>
  (value: string): Result<number> => {
    const result = parseInt(value.trim())

    if (isNaN(result)) return toError(i18n('common:validations.number.parsing'))
    else if (result < 1)
      return toError(i18n('common:validations.number.minimum'))
    else if (result > 1_000)
      return toError(i18n('common:validations.number.maximum'))
    else return toSuccess(result)
  }

const validateUrl =
  (i18n: ReturnType<typeof useTranslation<['common']>>['t']) =>
  (url: string): Result<string | null> => {
    const result = url.trim()

    if (result.length === 0) return toSuccess(null)
    else
      return isUrl(result)
        ? toSuccess(result)
        : toError(i18n('common:validations.text.url'))
  }
