import { Container, Link, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Page } from '../components/Page'
import { ResponsivePageTitle } from '../components/ResponsivePageTitle'
import { Root } from '../components/Root'

export const LegalNoticePage: React.FC = () => {
  const [i18n] = useTranslation(['app'])

  return (
    <Root
      title={i18n('app:pages.legalNotice.title')}
      description={i18n('app:pages.legalNotice.subtitle')}
    >
      <Page>
        <Container>
          <ResponsivePageTitle level={1}>
            {i18n('app:pages.legalNotice.title')}
          </ResponsivePageTitle>
          <Typography component="h2" variant="h5" paragraph>
            {i18n('app:pages.legalNotice.subtitle')}
          </Typography>
          <Typography paragraph>
            Niklas Klein & Thomas Wallner
            <br />
            Hasenheide 8<br />
            10967 Berlin
            <br />
            Germany
          </Typography>
          <Link href="mailto:hello@wishbear.app">hello@wishbear.app</Link>
        </Container>
      </Page>
    </Root>
  )
}
