import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface LogoProps {
  compact?: boolean
  size?: 'normal' | 'large' | 'small'
}

export const Logo: React.FC<LogoProps> = ({
  compact = false,
  size = 'normal',
}) => {
  const [i18n] = useTranslation(['common', 'app'])

  const iconSize = size === 'large' ? 48 : size === 'small' ? 24 : 32
  const labelFontSize = size === 'large' ? 32 : size === 'small' ? 16 : 24
  const labelMargin = size === 'small' ? 1 : 2

  return (
    <Box sx={{ alignItems: 'center', display: 'inline-flex' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
        width={iconSize}
        height={iconSize}
      >
        <path
          d="M63.29 9.93c14.92.22 16.4 3.61 20.77 3.73 6.32.18 15.98-11.97 29.07-.99 12.9 10.82 2.39 27.8 2.39 27.8s7.18 12.72 8.02 32.1c.61 14.03-4.79 31.49-29 41.39-22.29 9.12-55.55 7.3-75.88-8.31C2.9 93.56 4.01 75.92 4.44 69.91c1.13-15.77 8.02-28.44 8.02-28.44s-10.19-20.15 2.25-29.7c13.66-10.49 23.38 1.86 29 1.41 4.09-.34 5.43-3.46 19.58-3.25z"
          fill="#855b52"
        />
        <path
          d="M35.98 20.77c-.19-1.11-7.43-8.29-14.92-.99-5.77 5.63 0 15.42.99 14.64.76-.6 3.56-4.21 6.83-7.32 3.35-3.19 7.17-5.9 7.1-6.33zM92.5 20.84c-.05.62 3.81 2.95 7.32 6.26 3.55 3.35 6.75 7.67 7.32 7.6 1.13-.14 5.98-9.64-.35-14.99s-14.15-.7-14.29 1.13z"
          fill="#b99277"
        />
        <path
          d="M64.51 60.38c14.64 0 23.42 16.52 22.9 26.84-.47 9.39-5.82 17.18-23.84 17.08-18.02-.09-22.59-8.36-22.9-17.18-.28-7.88 5.35-26.74 23.84-26.74z"
          fill="#f2a258"
        />
        <path
          d="M92.92 59.7c.33 4.16-1.9 7.95-6.17 7.63-4.12-.32-7.13-3.66-7.13-7.84s2.22-7.14 5.56-7.6c4.08-.57 7.27 1.9 7.74 7.81z"
          fill="#171716"
        />
        <path
          d="M74.55 76.99c-.34 4.16-3.1 8.61-11.12 8.33-6.73-.24-9.9-4.72-10-8.89-.14-5.89 3-8.06 10.61-8.17 8.68-.11 10.98 3.01 10.51 8.73z"
          fill="#2f3030"
        />
        <path
          d="M48.37 61.25c-.43 4.15-4.33 7.06-7.98 6.17-4.01-.98-5.81-5.2-4.97-9.29.75-3.66 3.96-6.51 7.6-6.1 3.8.42 5.89 3.99 5.35 9.22z"
          fill="#171716"
        />
        <path
          d="M63.99 95.04c5.49 0 8.49-3.03 9.34-3.87s1.99-2.28 3.75-.84c1.5 1.22.02 3.12-.66 3.85-1.22 1.31-4.9 5.31-12.93 4.95-7.88-.35-10.91-3.92-11.76-4.76-.84-.84-2.25-3-.66-4.22 1.91-1.46 2.53.19 4.5 1.78s3.21 3.11 8.42 3.11z"
          fill="#2f3030"
        />
      </svg>
      {!compact && (
        <Typography
          ml={labelMargin}
          component="span"
          sx={{
            fontSize: labelFontSize,
            fontWeight: 'bold',
          }}
        >
          {i18n('common:app.name')}
        </Typography>
      )}
    </Box>
  )
}
