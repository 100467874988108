import { Box, BoxProps } from '@mui/material'
import React from 'react'

export interface EmptyStateHintProps extends BoxProps {
  imageUrl: string
  alt: string
}

export const EmptyStateHint: React.FC<EmptyStateHintProps> = ({
  imageUrl,
  alt,
  children,
  ...props
}) => {
  return (
    <Box {...props} p={2} maxWidth={500} ml="auto" mr="auto" textAlign="center">
      <img
        src={imageUrl}
        alt={alt}
        style={{
          filter: 'grayscale(1)',
          height: 150,
          marginBottom: 16,
          opacity: 0.6,
          width: 150,
        }}
      />
      {children}
    </Box>
  )
}
