import { Box, CardContent, Link, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { DebugAlert } from '../components/DebugAlert'
import { Root } from '../components/Root'
import { Splash } from '../components/Splash'
import BearLoveLetter from '../images/bear-love-letter.png'
import { LoginData } from '../../@types/app'
import { selfUrl } from '../utils/endpoints'

export interface LoginConfirmationPageProps {
  login: LoginData
}

export const LoginConfirmationPage: React.FC<LoginConfirmationPageProps> = ({
  login,
}) => {
  const [i18n] = useTranslation(['app'])
  const loginUrl = login.token ? selfUrl(`/login?session=${login.token}`) : null

  const confirmationUrl = loginUrl && (
    <DebugAlert sx={{ mt: 4 }}>
      <Link href={loginUrl}>{loginUrl}</Link>
    </DebugAlert>
  )

  return (
    <Root title={null} description={null}>
      <Splash>
        <CardContent sx={{ p: 4 }}>
          <Box textAlign="center">
            <img src={BearLoveLetter} style={{ height: 100 }} />
          </Box>
          <Typography
            align="center"
            component="h1"
            variant="h5"
            mt={2}
            paragraph
          >
            {i18n('app:pages.loginConfimration.title')}
          </Typography>
          <Typography align="center">
            <Trans
              i18nKey="app:pages.loginConfimration.description"
              values={{ email: login.email }}
            />
          </Typography>
          <Box mt={4} textAlign="center">
            <Link href={selfUrl('/')}>{i18n('app:pages.notFound.home')}</Link>
          </Box>
          {confirmationUrl}
        </CardContent>
      </Splash>
    </Root>
  )
}
