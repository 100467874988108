import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'
import {
  Link as ReactRouterDomLink,
  RelativeRoutingType,
  To,
} from 'react-router-dom'

export interface RouterLinkProps extends MuiLinkProps {
  reloadDocument?: boolean
  replace?: boolean
  /*eslint @typescript-eslint/no-explicit-any: "off"*/
  state?: any
  preventScrollReset?: boolean
  relative?: RelativeRoutingType
  to: To
}

export const RouterLink: React.FC<RouterLinkProps> = ({
  children,
  reloadDocument,
  replace,
  state,
  preventScrollReset,
  relative,
  to,
  ...props
}) => (
  <MuiLink
    component={ReactRouterDomLink}
    reloadDocument={reloadDocument}
    replace={replace}
    state={state}
    preventScrollReset={preventScrollReset}
    relative={relative}
    to={to}
    {...props}
  >
    {children}
  </MuiLink>
)
