import { useState } from 'react'
import { isError, Result } from '../utils/validations'

interface FieldData<A> {
  value: string
  result: Result<A>
  error: string | null
}

export interface InputManager<A> {
  handleBlur: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  value: string
  result: Result<A>
  hasError: boolean
  error: string | null
}

export function useInputManager<A>(
  value: string | null,
  validate: (value: string) => Result<A>,
): InputManager<A> {
  const initialValue = value || ''
  const [state, setState] = useState<FieldData<A>>({
    value: initialValue,
    result: validate(initialValue),
    error: null,
  })

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = event.currentTarget.value

    setState((previous) => {
      if (previous.error) {
        const result = validate(value)
        return {
          value,
          result,
          error: isError(result) ? result.error : null,
        }
      } else {
        return { value, result: validate(value), error: null }
      }
    })
  }

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = event.currentTarget.value
    const result = validate(value)
    setState({
      value,
      result,
      error: isError(result) ? result.error : null,
    })
  }

  return {
    handleBlur,
    handleChange,
    value: state.value,
    result: state.result,
    hasError: state.error !== null,
    error: state.error,
  }
}
