import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  TextField,
} from '@mui/material'
import { FormEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormDialog } from '../components/FormDialog'
import { useGlobalErrorHandler } from '../hooks/useGlobalErrorHandler'
import { useInputManager } from '../hooks/useInputManager'
import { putSelfEmail } from '../utils/endpoints'
import {
  isEmail,
  isError,
  isSuccess,
  Result,
  toError,
  toSuccess,
} from '../utils/validations'

export interface EditEmailDialogProps extends Omit<DialogProps, 'title'> {
  title: ReactNode
  session: string
  onClose: () => void
  onComplete: (token: string | null) => void
}

export const EditEmailDialog: React.FC<EditEmailDialogProps> = ({
  title,
  session,
  onClose,
  onComplete,
  ...props
}) => {
  const [i18n, { resolvedLanguage }] = useTranslation(['common', 'app'])
  const escalate = useGlobalErrorHandler()
  const [loading, setLoading] = useState(false)
  const email = useInputManager('', validateEmail(i18n))
  const [focus, setFocus] = useState<HTMLInputElement | null>(null)

  const handleSubmit = async (event: FormEvent<Element>) => {
    event.preventDefault()

    if (isSuccess(email.result)) {
      setLoading(true)

      try {
        const token = await putSelfEmail(
          session,
          email.result.success,
          resolvedLanguage || 'en',
        )
        setLoading(false)
        onComplete(token)
      } catch (error) {
        setLoading(false)
        escalate(error)
      }
    }
  }

  const handleClose = () => {
    if (!loading) onClose()
  }

  const handleInputRef = useCallback((node: HTMLInputElement) => {
    if (node !== null) setFocus(node)
  }, [])

  useEffect(() => {
    if (focus !== null) focus.focus()
  }, [focus])

  return (
    <FormDialog
      {...props}
      fullWidth
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText mb={4}>
          {i18n('app:dialogs.editEmail.description')}
        </DialogContentText>
        <TextField
          autoComplete="email"
          error={email.hasError}
          fullWidth
          helperText={email.error || ' '}
          inputRef={handleInputRef}
          label={i18n('app:dialogs.editEmail.email.label')}
          onBlur={email.handleBlur}
          onChange={email.handleChange}
          placeholder={i18n('app:dialogs.editEmail.email.placeholder')}
        />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={loading} onClick={handleClose}>
          {i18n('common:app.cancel')}
        </Button>
        <LoadingButton
          disabled={isError(email.result)}
          loading={loading}
          type="submit"
        >
          {i18n('common:app.save')}
        </LoadingButton>
      </DialogActions>
    </FormDialog>
  )
}

const validateEmail =
  (i18n: ReturnType<typeof useTranslation<['common']>>['t']) =>
  (value: string): Result<string> => {
    const result = value.trim()

    if (result.length === 0)
      return toError(i18n('common:validations.text.required'))
    else if (result.length > 250)
      return toError(i18n('common:validations.text.maximum'))
    else if (!isEmail(result))
      return toError(i18n('common:validations.text.email'))
    else return toSuccess(value)
  }
