import { Grid, GridProps } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LimitedTextField } from '../components/LimitedTextField'
import { useInputManager } from '../hooks/useInputManager'
import { isSuccess, Result, toError, toSuccess } from '../utils/validations'

export interface EditWishlistFormData {
  title: string
  description: string | null
}

export interface EditWishlistFormProps extends GridProps {
  data: EditWishlistFormData
  loading: boolean
  onComplete: (data: EditWishlistFormData) => void
  onIncomplete: () => void
}

export const EditWishlistForm: React.FC<EditWishlistFormProps> = ({
  data,
  loading,
  onComplete,
  onIncomplete,
  ...props
}) => {
  const [i18n] = useTranslation(['common', 'app'])
  const title = useInputManager(data.title, validateTitle(i18n))
  const description = useInputManager(
    data.description,
    validateDescription(i18n),
  )

  useEffect(() => {
    if (isSuccess(title.result) && isSuccess(description.result)) {
      onComplete({
        title: title.result.success,
        description: description.result.success,
      })
    } else onIncomplete()
  }, [title.value, description.value])

  return (
    <Grid {...props} container gap={2}>
      <Grid item xs={12}>
        <LimitedTextField
          disabled={loading}
          error={title.hasError}
          fullWidth
          helperText={title.error}
          label={i18n('app:forms.editWishlist.title.label')}
          limit={80}
          onBlur={title.handleBlur}
          onChange={title.handleChange}
          placeholder={i18n('app:forms.editWishlist.title.placeholder')}
          required
          value={title.value}
        />
      </Grid>
      <Grid item xs={12}>
        <LimitedTextField
          disabled={loading}
          error={description.hasError}
          fullWidth
          helperText={description.error}
          label={i18n('app:forms.editWishlist.description.label')}
          limit={500}
          multiline
          onBlur={description.handleBlur}
          onChange={description.handleChange}
          rows={5}
          value={description.value}
        />
      </Grid>
    </Grid>
  )
}

const validateTitle =
  (i18n: ReturnType<typeof useTranslation<['common']>>['t']) =>
  (value: string): Result<string> => {
    const result = value.trim()

    if (result.length === 0)
      return toError(i18n('common:validations.text.required'))
    else if (result.length > 80)
      return toError(i18n('common:validations.text.maximum'))
    else return toSuccess(result)
  }

const validateDescription =
  (i18n: ReturnType<typeof useTranslation<['common']>>['t']) =>
  (value: string): Result<string | null> => {
    const result = value.trim()
    const length = result.length
    if (length > 500) return toError(i18n('common:validations.text.maximum'))
    else return toSuccess(length === 0 ? null : result)
  }
