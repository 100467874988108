import { CssBaseline } from '@mui/material'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { ScrollToTop } from './components/ScrollToTop'
import { GlobalErrorHandlerProvider } from './hooks/useGlobalErrorHandler'
import { NotificationProvider } from './hooks/useGlobalMessage'
import { SessionProvider } from './hooks/useSession'
import { ThemeModeProvider } from './hooks/useThemeMode'
import { ChristmasPage } from './pages/ChristmasPage'
import { IndexPage } from './pages/IndexPage'
import { LegalNoticePage } from './pages/LegalNoticePage'
import { LoginPage } from './pages/LoginPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { ProfilePage } from './pages/ProfilePage'
import { StartPage } from './pages/StartPage'
import { VerifyPage } from './pages/VerifyPage'
import { WishlistPage } from './pages/WishlistPage'
import { WishlistsPage } from './pages/WishlistsPage'

export const App: React.FC = () => {
  return (
    <ThemeModeProvider>
      <SWRConfig>
        <NotificationProvider>
          <SessionProvider>
            <CssBaseline />
            <BrowserRouter>
              <GlobalErrorHandlerProvider>
                <ScrollToTop />
                <Routes>
                  <Route path="/" element={<IndexPage />} />
                  <Route path="/christmas" element={<ChristmasPage />} />
                  <Route path="/legal-notice" element={<LegalNoticePage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/start" element={<StartPage />} />
                  <Route path="/verify" element={<VerifyPage />} />
                  <Route
                    path="/wishlist/:wishlistReference"
                    element={<WishlistPage />}
                  />
                  <Route path="/wishlists" element={<WishlistsPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </GlobalErrorHandlerProvider>
            </BrowserRouter>
          </SessionProvider>
        </NotificationProvider>
      </SWRConfig>
    </ThemeModeProvider>
  )
}
