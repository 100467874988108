import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContentText,
  DialogProps,
} from '@mui/material'
import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogImageContent } from '../components/DialogImageContent'
import { FormDialog } from '../components/FormDialog'
import {
  CreateItemReservationForm,
  CreateReservationFormData,
} from '../forms/CreateReservationForm'
import { useGlobalErrorHandler } from '../hooks/useGlobalErrorHandler'
import { useSession } from '../hooks/useSession'
import BearShush from '../images/bear-shush.png'
import {
  postUsers,
  postWishlistsReferenceItemsReferenceReservations,
} from '../utils/endpoints'
import { mutateSelf, mutateWishlist } from '../utils/mutations'

export interface CreateReservationDialogProps extends DialogProps {
  wishlistReference: string
  itemReference: string
  session: string | null
  onClose: () => void
}

export const CreateReservationDialog: React.FC<
  CreateReservationDialogProps
> = ({ wishlistReference, itemReference, session, onClose, ...props }) => {
  const [i18n] = useTranslation(['common', 'app'])
  const escalate = useGlobalErrorHandler()
  const { setSession } = useSession()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<CreateReservationFormData | null>(null)

  const handleClose = () => !loading && onClose()

  const handleSubmit = async (event: FormEvent<Element>) => {
    event.preventDefault()

    if (!data) return

    setLoading(true)

    try {
      const currentOrNewSession: string =
        session ||
        (await postUsers().then((user) => {
          setSession(user.session)
          mutateSelf(user)
          return user.session
        }))

      const updatedWishlist =
        await postWishlistsReferenceItemsReferenceReservations(
          currentOrNewSession,
          wishlistReference,
          itemReference,
          data,
        )
      setLoading(false)
      mutateWishlist(updatedWishlist, currentOrNewSession)
      onClose()
    } catch (error) {
      setLoading(false)
      escalate(error)
    }
  }

  return (
    <FormDialog {...props} onClose={handleClose} onSubmit={handleSubmit}>
      <DialogImageContent
        image={{
          alt: i18n('app:dialogs.createReservation.image.alt'),
          url: BearShush,
        }}
        title={i18n('app:dialogs.createReservation.title')}
      >
        <DialogContentText paragraph>
          {i18n('app:dialogs.createReservation.description1')}
        </DialogContentText>
        <DialogContentText paragraph>
          {i18n('app:dialogs.createReservation.description2')}
        </DialogContentText>
        <DialogContentText>
          {i18n('app:dialogs.createReservation.description3')}
        </DialogContentText>
        <CreateItemReservationForm
          disabled={loading}
          mt={4}
          onComplete={setData}
        />
      </DialogImageContent>
      <DialogActions>
        <Button color="inherit" disabled={loading} onClick={handleClose}>
          {i18n('common:app.cancel')}
        </Button>
        <LoadingButton disabled={data === null} loading={loading} type="submit">
          {i18n('common:app.save')}
        </LoadingButton>
      </DialogActions>
    </FormDialog>
  )
}
