import { PaletteMode, ThemeProvider, useMediaQuery } from '@mui/material'
import React, { PropsWithChildren, useContext, useState } from 'react'
import { darkTheme, lightTheme } from '../utils/themes'

export const themeModeContext = React.createContext<ThemeModeContext>({
  themeMode: 'light',
  toggleThemeMode: () => {
    console.error('toggleColorMode called on uninitialized context')
  },
})

interface ThemeModeContext {
  themeMode: PaletteMode
  toggleThemeMode: () => void
}

export const useThemeMode: () => ThemeModeContext = () =>
  useContext(themeModeContext)

export const ThemeModeProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const storedThemeMode = localStorage.getItem('themeMode') as PaletteMode
  const preferredThemeMode = useMediaQuery('(prefers-color-scheme: dark)')
    ? 'dark'
    : 'light'

  const [mode, setMode] = useState<PaletteMode>(
    storedThemeMode || preferredThemeMode,
  )

  const themeMode = {
    themeMode: mode,
    toggleThemeMode: () => {
      setMode((prevMode) => {
        const mode = prevMode === 'light' ? 'dark' : 'light'
        localStorage.setItem('themeMode', mode)
        return mode
      })
    },
  }

  const theme = React.useMemo(() => {
    return mode === 'dark' ? darkTheme : lightTheme
  }, [mode])

  return (
    <themeModeContext.Provider value={themeMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </themeModeContext.Provider>
  )
}
