import { AlertTitle, Box, Button } from '@mui/material'
import Alert, { AlertProps } from '@mui/material/Alert'
import React from 'react'
import { useTranslation } from 'react-i18next'

export interface EmailReminderAlertProps extends AlertProps {
  onClickAddEmail: () => void
}

export const EmailReminderAlert: React.FC<EmailReminderAlertProps> = ({
  onClickAddEmail,
  ...props
}) => {
  const [i18n] = useTranslation(['common'])

  return (
    <Alert
      {...props}
      severity="warning"
      sx={{
        ...props?.sx,
        '& .MuiAlert-message': {
          flex: 1,
        },
      }}
    >
      <AlertTitle>
        {i18n('common:components.emailReminderAlert.title')}
      </AlertTitle>
      <Box>{i18n('common:components.emailReminderAlert.description')}</Box>
      <Box mt={3} textAlign="right">
        <Button color="warning" onClick={onClickAddEmail} variant="outlined">
          {i18n('common:components.emailReminderAlert.action.label')}
        </Button>
      </Box>
    </Alert>
  )
}
