import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogProps } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationButton } from '../components/ConfirmationButton'
import { DialogImageContent } from '../components/DialogImageContent'
import { FormDialog } from '../components/FormDialog'
import {
  EditWishlistForm,
  EditWishlistFormData,
} from '../forms/EditWishlistForm'
import { useGlobalErrorHandler } from '../hooks/useGlobalErrorHandler'
import BearHoney from '../images/bear-honey.png'
import { WishlistBaseData } from '../../@types/app'
import {
  deleteWishlistsReference,
  putWishlistsReference,
} from '../utils/endpoints'
import { mutateDeleteWishlist, mutateWishlist } from '../utils/mutations'

export interface EditWishlistDialogProps extends DialogProps {
  session: string
  wishlist: WishlistBaseData<number>
  onDeleted?: () => void
  onClose: () => void
}

export const EditWishlistDialog: React.FC<EditWishlistDialogProps> = ({
  session,
  wishlist,
  onClose,
  onDeleted,
  ...props
}) => {
  const [i18n] = useTranslation(['common', 'app'])
  const escalate = useGlobalErrorHandler()
  const [submitting, setSubmitting] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [data, setData] = useState<EditWishlistFormData | null>(null)

  const loading = submitting || deleting

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    if (data) {
      setSubmitting(true)

      try {
        const updatedWisthlist = await putWishlistsReference(
          session,
          wishlist.reference,
          data,
        )
        mutateWishlist(updatedWisthlist, session)
        setSubmitting(false)
        onClose()
      } catch (error) {
        setSubmitting(false)
        escalate(error)
      }
    }
  }

  const handleClickDelete = async () => {
    setDeleting(true)

    try {
      await deleteWishlistsReference(session, wishlist.reference)
      setDeleting(false)
      mutateDeleteWishlist(wishlist.reference, session)
      onClose()
      onDeleted && onDeleted()
    } catch (error) {
      setDeleting(false)
      escalate(error)
    }
  }

  const handleClose = () => {
    if (!loading) onClose()
  }

  return (
    <FormDialog
      {...props}
      fullWidth
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      <DialogImageContent
        image={{
          alt: i18n('app:dialogs.editWishlist.image.alt'),
          url: BearHoney,
        }}
        title={i18n('app:dialogs.editWishlist.title')}
      >
        <EditWishlistForm
          data={wishlist}
          loading={loading}
          mt={1}
          onComplete={setData}
          onIncomplete={() => setData(null)}
        />
      </DialogImageContent>
      <DialogActions>
        <Box flex={1}>
          <ConfirmationButton
            color="error"
            condition={wishlist.items > 0}
            disabled={loading}
            loading={deleting}
            onClick={handleClickDelete}
            ConfirmationBoxProps={{ ml: 1 }}
          >
            {i18n('app:dialogs.editWishlist.action.delete.label')}
          </ConfirmationButton>
        </Box>
        <Button color="inherit" disabled={loading} onClick={handleClose}>
          {i18n('common:app.cancel')}
        </Button>
        <LoadingButton
          disabled={data === null || loading}
          loading={submitting}
          type="submit"
        >
          {i18n('common:app.save')}
        </LoadingButton>
      </DialogActions>
    </FormDialog>
  )
}
