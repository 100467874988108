import { Box } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Footer } from './Footer'
import { Header } from './Header'

interface PageProps extends PropsWithChildren {
  disableGutter?: boolean
}

export const Page: React.FC<PageProps> = ({
  disableGutter = false,
  children,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      overflowX: 'hidden',
    }}
  >
    <Header />
    <Box sx={{ flexGrow: 1, my: disableGutter ? 0 : 4 }}>{children}</Box>
    <Footer />
  </Box>
)
