import { mutate } from 'swr'
import { UserData, WishlistData, WishlistSummaryData } from '../../@types/app'

export const mutateWishlist: (
  wishlist: WishlistData,
  session: string | null,
) => void = (wishlist, session) => {
  mutate([`/wishlists/${wishlist.reference}`, session], wishlist, {
    revalidate: false,
  })
  mutate<WishlistSummaryData[]>(
    [`/wishlists`, session],
    (wishlists) => {
      const summary: WishlistSummaryData = {
        ...wishlist,
        items: wishlist.items.length,
        previews: wishlist.items
          .map((item) => item.image)
          .filter((image): image is string => image !== null)
          .slice(0, 4),
      }

      if (wishlists) {
        const index = wishlists.findIndex(
          (haystack) => haystack.reference === wishlist.reference,
        )

        if (index === -1) return [summary, ...wishlists]
        else {
          const copy = [...wishlists]
          copy[index] = summary
          return copy
        }
      } else return [summary]
    },
    { revalidate: false },
  )
}

export function mutateDeleteWishlist(
  wishlistReference: string,
  session: string | null,
): void {
  mutate<WishlistSummaryData[]>(
    [`/wishlists`, session],
    (wishlists) =>
      wishlists
        ? wishlists.filter(
            (wishlist) => wishlist.reference !== wishlistReference,
          )
        : [],
    { revalidate: false },
  )
}

export const mutateSelf: (user: UserData) => void = (user) => {
  mutate('/self', user, { revalidate: false })
}
