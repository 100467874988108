import { alpha, Box, Card } from '@mui/material'
import { PropsWithChildren } from 'react'
import BearPattern from '../images/bear-pattern.png'
import { Footer } from './Footer'

export const Splash: React.FC<PropsWithChildren> = ({ children }) => (
  <>
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      position="relative"
      width="100vw"
      sx={[
        {
          backgroundImage: `url(${BearPattern})`,
          backgroundColor: 'primary.main',
          backgroundPosition: 'center',
          backgroundRepeat: 'repeat',
          backgroundSize: 'max(600px, min(1200px, 100vw))',
          minHeight: '100vh',
          '&::before': {
            background: (theme) =>
              `radial-gradient(circle, ${alpha(
                theme.palette.primary.main,
                0,
              )} 35%, ${theme.palette.primary.main} 90%)`,
            bottom: 0,
            content: "''",
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          },
        },
        {
          backgroundImage: (theme) =>
            `url(${BearPattern}), radial-gradient(circle, ${theme.palette.primary.light} 25%, ${theme.palette.primary.main} 75%)`,
          minHeight: '-webkit-fill-available',
        },
      ]}
    >
      <Card
        elevation={5}
        sx={{
          maxWidth: 500,
          mx: 2,
          my: 8,
          position: 'relative',
          width: '100%',
        }}
      >
        {children}
      </Card>
    </Box>
    <Footer />
  </>
)
