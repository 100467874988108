import CheckIcon from '@mui/icons-material/Check'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  Box,
  Button,
  DialogActions,
  DialogContentText,
  DialogProps,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogImageContent } from '../components/DialogImageContent'
import { ResponsiveDialog } from '../components/ResponsiveDialog'
import BearConfettiBall from '../images/bear-confetti-ball.png'
import { selfUrl } from '../utils/endpoints'

export interface ShareWishlistDialogProps extends DialogProps {
  wishlistReference: string
  onClose: () => void
}

export const ShareWishlistDialog: React.FC<ShareWishlistDialogProps> = ({
  wishlistReference,
  onClose,
  ...props
}) => {
  const [i18n] = useTranslation(['common', 'app'])
  const [copied, setCopied] = useState(false)
  const [focus, setFocus] = useState<HTMLInputElement | null>(null)

  const url = selfUrl(`/wishlist/${wishlistReference}`)

  const handleClick = () => {
    setCopied(true)
    navigator.clipboard.writeText(url)
  }

  const handleInputRef = useCallback((node: HTMLInputElement) => {
    if (node !== null) setFocus(node)
  }, [])

  useEffect(() => {
    if (focus !== null) focus.select()
  }, [focus])

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 3000)
      return () => clearTimeout(timeout)
    }
  }, [copied])

  const button = copied ? (
    <CheckIcon color="success" sx={{ display: 'block', m: 1 }} />
  ) : (
    <Tooltip title={i18n('app:dialogs.shareWishlist.action.copy.title')}>
      <IconButton color="primary" onClick={handleClick}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  )

  return (
    <ResponsiveDialog {...props} onClose={onClose} fullWidth>
      <DialogImageContent
        title={i18n('app:dialogs.shareWishlist.title')}
        image={{
          alt: i18n('app:dialogs.shareWishlist.image.alt'),
          url: BearConfettiBall,
        }}
      >
        <DialogContentText paragraph>
          {i18n('app:dialogs.shareWishlist.description')}
        </DialogContentText>
        <Box position="relative">
          <TextField
            fullWidth
            inputProps={{
              inputMode: 'none',
              readonly: 'readonly',
              sx: { pr: 6 },
            }}
            inputRef={handleInputRef}
            value={url}
          />
          <Box position="absolute" right={8} top={8}>
            {button}
          </Box>
        </Box>
      </DialogImageContent>
      <DialogActions>
        <Button onClick={onClose}>{i18n('common:app.ok')}</Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}
