import FlareIcon from '@mui/icons-material/Flare'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import PhonelinkIcon from '@mui/icons-material/Phonelink'
import { Box, Grid, SvgIconProps } from '@mui/material'
import React, { PropsWithChildren, ReactElement } from 'react'
import { ResponsivePageTitle } from './ResponsivePageTitle'
import NoAccountsIcon from '@mui/icons-material/NoAccounts'
import CakeIcon from '@mui/icons-material/Cake'
import GpsOffIcon from '@mui/icons-material/GpsOff'
import { useTranslation } from 'react-i18next'

export const Why: React.FC = () => {
  const [i18n] = useTranslation(['common'])

  return (
    <>
      <ResponsivePageTitle level={2} sx={{ mb: 4, textAlign: 'center' }}>
        {i18n('common:components.why.title')}
      </ResponsivePageTitle>
      <Grid
        container
        component="ul"
        spacing={4}
        sx={{ listStyle: 'none', p: 0 }}
      >
        <Grid item component="li" xs={12} md={6}>
          <Item
            icon={(props) => <FlareIcon {...props} />}
            title={i18n('common:components.why.items.quality.title')}
          >
            {i18n('common:components.why.items.quality.description')}
          </Item>
        </Grid>
        <Grid item component="li" xs={12} md={6}>
          <Item
            icon={(props) => <MoneyOffIcon {...props} />}
            title={i18n('common:components.why.items.free.title')}
          >
            {i18n('common:components.why.items.free.description')}
          </Item>
        </Grid>
        <Grid item component="li" xs={12} md={6}>
          <Item
            icon={(props) => <PhonelinkIcon {...props} />}
            title={i18n('common:components.why.items.responsive.title')}
          >
            {i18n('common:components.why.items.responsive.description')}
          </Item>
        </Grid>
        <Grid item component="li" xs={12} md={6}>
          <Item
            icon={(props) => <NoAccountsIcon {...props} />}
            title={i18n('common:components.why.items.account.title')}
          >
            {i18n('common:components.why.items.account.description')}
          </Item>
        </Grid>
        <Grid item component="li" xs={12} md={6}>
          <Item
            icon={(props) => <CakeIcon {...props} />}
            title={i18n('common:components.why.items.occasions.title')}
          >
            {i18n('common:components.why.items.occasions.description')}
          </Item>
        </Grid>
        <Grid item component="li" xs={12} md={6}>
          <Item
            icon={(props) => <GpsOffIcon {...props} />}
            title={i18n('common:components.why.items.tracking.title')}
          >
            {i18n('common:components.why.items.tracking.description')}
          </Item>
        </Grid>
      </Grid>
    </>
  )
}

interface ItemProps extends PropsWithChildren {
  icon: (props: SvgIconProps) => ReactElement
  title: string
}

const Item: React.FC<ItemProps> = ({ icon, title, children }) => {
  return (
    <>
      <ResponsivePageTitle
        level={3}
        sx={{ color: (theme) => theme.palette.primary.main }}
      >
        <Box display="flex" alignItems="center">
          {icon({ fontSize: 'inherit', sx: { mr: 2 } })} {title}
        </Box>
      </ResponsivePageTitle>
      <Box ml={{ xs: 5, sm: 6.5 }} mt={2}>
        {children}
      </Box>
    </>
  )
}
