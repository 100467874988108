import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WishlistData } from '../../@types/app'
import { useGlobalErrorHandler } from '../hooks/useGlobalErrorHandler'
import { useSession } from '../hooks/useSession'
import { postUsers, postWishlists } from '../utils/endpoints'
import { mutateSelf, mutateWishlist } from '../utils/mutations'

export interface RegisterFormProps {
  label: string
  disabled: boolean
  onLoading: (loading: boolean) => void
  onComplete: (wishlist: WishlistData) => void
}

export const RegisterForm: React.FC<RegisterFormProps> = ({
  label,
  disabled,
  onLoading,
  onComplete,
}) => {
  const [i18n, { resolvedLanguage }] = useTranslation(['common'])
  const escalate = useGlobalErrorHandler()
  const { setSession } = useSession()
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)
    onLoading(true)

    try {
      const user = await postUsers()
      const wishlist = await postWishlists(
        user.session,
        i18n('common:app.defaultWishlistTitle'),
        resolvedLanguage || 'en',
      )
      setLoading(false)
      onLoading(false)
      mutateSelf(user)
      mutateWishlist(wishlist, user.session)
      setSession(user.session)
      onComplete(wishlist)
    } catch (error) {
      setLoading(false)
      onLoading(false)
      escalate(error)
    }
  }

  return (
    <LoadingButton
      fullWidth
      disabled={disabled}
      loading={loading}
      variant="contained"
      onClick={handleClick}
    >
      {label}
    </LoadingButton>
  )
}
