import { Box, BoxProps, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'

export interface LabeledItemProps extends PropsWithChildren, BoxProps {
  label: string
}

export const LabeledItem: React.FC<LabeledItemProps> = ({
  label,
  children,
  ...props
}) => {
  return (
    <Box {...props}>
      <Typography color="text.secondary" display="block" variant="caption">
        {label}
      </Typography>
      {children}
    </Box>
  )
}
