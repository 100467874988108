import { Box, Button, Divider, Skeleton, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EmailAlert } from '../components/EmailAlert'
import { EmailConfirmationAlert } from '../components/EmailConfirmationAlert'
import { LabeledItem } from '../components/LabeledItem'
import { Page } from '../components/Page'
import { Root } from '../components/Root'
import { EditEmailDialog } from '../dialogs/EditEmailDialog'
import { EditAccountForm } from '../forms/EditAccountForm'
import { useGetSelf } from '../hooks/swr'
import { useSession } from '../hooks/useSession'
import { UserData } from '../../@types/app'
import { BreadcrumbNavigation } from '../components/BreadcrumbNavigation'

export const ProfilePage: React.FC = () => {
  const [i18n] = useTranslation(['app'])
  const self = useGetSelf(true)
  const { removeSession } = useSession()
  const navigate = useNavigate()

  const handleClickLogout = (): void => {
    removeSession()
    navigate('/start', { replace: true })
  }

  const generalForm = self.value ? (
    <EditAccountForm self={self.value} />
  ) : (
    <Skeleton height={150} variant="rounded" />
  )

  return (
    <Root
      title={self.value?.name || i18n('app:pages.profile.title')}
      description={null}
    >
      <Page>
        <Container>
          <BreadcrumbNavigation current={i18n('app:pages.profile.title')} />
          <Typography variant="h2" component="h1" mb={4}>
            {i18n('app:pages.profile.title')}
          </Typography>
          <Account self={self.value} />
          <Divider sx={{ my: 4 }} />
          <Typography variant="h4" component="h2" mb={3}>
            {i18n('app:pages.profile.general.title')}
          </Typography>
          {generalForm}
          <Divider sx={{ my: 4 }} />
          <Box textAlign="center">
            <Button variant="text" onClick={handleClickLogout}>
              {i18n('app:pages.profile.logout.label')}
            </Button>
          </Box>
        </Container>
      </Page>
    </Root>
  )
}

interface AccountProps {
  self: UserData | undefined
}

const Account: React.FC<AccountProps> = ({ self }) => {
  const [i18n] = useTranslation(['app'])

  return (
    <>
      <Typography variant="h4" component="h2" paragraph>
        {i18n('app:pages.profile.account.title')}
      </Typography>
      <AccountForm self={self} />
    </>
  )
}

interface AccountFormProps {
  self: UserData | undefined
}

const AccountForm: React.FC<AccountFormProps> = ({ self }) => {
  const [i18n] = useTranslation(['app'])
  const [showDialog, setShowDialog] = useState(false)
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false)
  const [token, setToken] = useState<string | null>(null)

  const dialog = self && (
    <EditEmailDialog
      title={i18n('app:pages.profile.account.dialog.title')}
      onClose={() => setShowDialog(false)}
      onComplete={(token) => {
        setShowDialog(false)
        setShowConfirmationAlert(true)
        setToken(token)
      }}
      open={showDialog}
      session={self.session}
    />
  )

  if (!self) return <Skeleton height={150} variant="rounded" />
  if (showConfirmationAlert) return <EmailConfirmationAlert token={token} />
  else if (self.email)
    return (
      <>
        {dialog}
        <LabeledItem label={i18n('app:pages.profile.account.email.label')}>
          {self?.email}
        </LabeledItem>
        <Box mt={2} textAlign="right">
          <Button onClick={() => setShowDialog(true)} variant="contained">
            {i18n('app:pages.profile.account.action.update.label')}
          </Button>
        </Box>
      </>
    )
  else return <EmailAlert self={self} />
}
