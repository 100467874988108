import { useEffect } from 'react'
import useSWR, { Fetcher, Key } from 'swr'
import { UserData, WishlistData, WishlistSummaryData } from '../../@types/app'
import {
  authorizedFetch,
  unauthorizedFetch,
  UnexpectedResponseError,
} from '../utils/endpoints'
import { useGlobalErrorHandler } from './useGlobalErrorHandler'
import { useSession } from './useSession'

/*eslint @typescript-eslint/no-explicit-any: "off"*/
type UnauthorizedFetcher = (path: string) => Promise<any>
type AuthorizedFetcher = (path: string, session: string) => Promise<any>

const unauthorizedFetcher: UnauthorizedFetcher = (path) =>
  unauthorizedFetch('GET', path).then((response) => {
    if (response.status >= 200 && response.status < 300) return response.json()
    else throw new UnexpectedResponseError(response)
  })

const authorizedFetcher: AuthorizedFetcher = (path, session) =>
  authorizedFetch('GET', path, session).then((response) => {
    if (response.status >= 200 && response.status < 300) return response.json()
    else throw new UnexpectedResponseError(response)
  })

interface FetchResult<Data, HandledError> {
  loading: boolean
  value: Data | undefined
  unhandledError: any | undefined
  handledError: HandledError | undefined
}

function useFetchResult<SWRKey extends Key, Data, HandledError>(
  key: SWRKey,
  fetcher: Fetcher<Data, SWRKey> | null,
  onError: (error: any, status: number | undefined) => HandledError | undefined,
): FetchResult<Data, HandledError> {
  const { data, error } = useSWR(key, fetcher)
  const escalate = useGlobalErrorHandler()

  const status =
    error instanceof UnexpectedResponseError ? error.response.status : undefined

  const handledError = error ? onError(error, status) : undefined
  const unhandledError = handledError ? undefined : error

  useEffect(() => {
    if (unhandledError !== undefined) escalate(unhandledError)
  }, [unhandledError === undefined])

  return {
    loading: data === undefined && error === undefined,
    value: data,
    unhandledError,
    handledError,
  }
}

export const useGetSelf = (
  required: boolean,
): FetchResult<UserData, undefined> => {
  const escalate = useGlobalErrorHandler()
  const { session } = useSession()

  useEffect(() => {
    if (session === null && required)
      escalate(new UnexpectedResponseError(new Response(null, { status: 401 })))
  }, [session])

  return useFetchResult(
    session ? ['/self', session] : null,
    authorizedFetcher,
    () => undefined,
  )
}

/*eslint @typescript-eslint/no-unused-vars: "off"*/
function useFetchResultUnauthorized<Data, HandledError>(
  path: string,
  onError: (error: any, status: number | undefined) => HandledError | undefined,
): FetchResult<Data, HandledError> {
  return useFetchResult(path, unauthorizedFetcher, onError)
}

function useFetchResultAuthorized<Data, HandledError>(
  path: string,
  onError: (error: any, status: number | undefined) => HandledError | undefined,
  required: boolean,
): FetchResult<Data, HandledError> {
  const self = useGetSelf(required)
  const session = self.value?.session

  return useFetchResult(
    session ? [path, session] : null,
    authorizedFetcher,
    onError,
  )
}

export const useGetWishlists = (): FetchResult<
  WishlistSummaryData[],
  undefined
> => useFetchResultAuthorized('/wishlists', () => undefined, true)

export const useGetWishlistsReference = (
  reference: string,
): FetchResult<WishlistData, 'notFound'> => {
  const self = useGetSelf(false)
  const session = self.value?.session

  return useFetchResult(
    [`/wishlists/${reference}`, session],
    (path, session) =>
      session ? authorizedFetcher(path, session) : unauthorizedFetcher(path),
    (_, status) => (status === 404 || status === 422 ? 'notFound' : undefined),
  )
}
