import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  ScopedCssBaseline,
  ThemeProvider,
  useTheme,
} from '@mui/material'
import { darkTheme } from '../utils/themes'
import { LanguageSelector } from './LanguageSelector'
import { Logo } from './Logo'
import { RouterLink } from './RouterLink'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { useTranslation } from 'react-i18next'

export const Footer: React.FC = () => {
  const mode = useTheme().palette.mode
  const [i18n] = useTranslation(['common'])
  const currentYear = new Date().getFullYear()

  return (
    <ThemeProvider theme={darkTheme}>
      <ScopedCssBaseline
        sx={{
          backgroundColor: (theme) =>
            mode === 'light'
              ? theme.palette.background.default
              : theme.palette.background.paper,
          py: 6,
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            alignItems="center"
            spacing={4}
            textAlign={{
              xs: 'center',
              md: 'left',
            }}
          >
            <Grid item xs={12} md={6}>
              <RouterLink
                color="inherit"
                display="inline-flex"
                to="/"
                underline="none"
              >
                <Logo size="large" />
              </RouterLink>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              textAlign={{
                xs: 'center',
                md: 'right',
              }}
            >
              <LanguageSelector />
            </Grid>
            <Grid
              item
              component="ul"
              xs={12}
              sx={{ listStyle: 'none', m: 0, p: 0 }}
            >
              <li>
                <Link
                  color="inherit"
                  href="mailto:hello@wishbear.app"
                  underline="hover"
                >
                  hello@wishbear.app
                </Link>
              </li>
              <li style={{ marginTop: 8 }}>
                <RouterLink
                  color="inherit"
                  to="/legal-notice"
                  underline="hover"
                >
                  {i18n('common:components.footer.privacyPolicy')}
                </RouterLink>
              </li>
            </Grid>
          </Grid>
          <Divider sx={{ my: 4 }} />
          <Grid container sx={{ opacity: 0.6 }} rowGap={2}>
            <Grid
              item
              xs={12}
              sm={6}
              textAlign={{
                xs: 'center',
                sm: 'left',
              }}
            >
              © Copyright {currentYear} Wishbear
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              textAlign={{
                xs: 'center',
                sm: 'right',
              }}
            >
              <Box alignItems="center" display="inline-flex">
                <FavoriteBorderIcon fontSize="inherit" sx={{ mr: 1 }} />
                <span>Made in Berlin</span>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  )
}
