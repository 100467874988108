import { Box, CardContent, Divider, Link, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Logo } from '../components/Logo'
import { Root } from '../components/Root'
import { Splash } from '../components/Splash'
import { LoginForm } from '../forms/LoginForm'
import { RegisterForm } from '../forms/RegisterForm'
import { useSession } from '../hooks/useSession'
import { LoginData } from '../../@types/app'
import { selfUrl } from '../utils/endpoints'
import { LoginConfirmationPage } from './LoginConfirmationPage'

export const StartPage: React.FC = () => {
  const [i18n] = useTranslation(['app'])
  const [login, setLogin] = useState<LoginData | null>(null)
  const { session } = useSession()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (session !== null) navigate('/wishlists', { replace: true })
  }, [])

  if (login !== null) return <LoginConfirmationPage login={login} />

  return (
    <Root title={null} description={null}>
      <Splash>
        <CardContent sx={{ p: 4 }}>
          <Box mb={3} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Link color="inherit" href={selfUrl('/')} underline="none">
              <Logo size="large" />
            </Link>
          </Box>
          <Typography align="center" mb={3}>
            {i18n('app:pages.start.register.description')}
          </Typography>
          <RegisterForm
            label={i18n('app:pages.start.register.label')}
            disabled={loading}
            onLoading={setLoading}
            onComplete={(wishlist) =>
              navigate(`/wishlist/${wishlist.reference}`, { replace: true })
            }
          />
          <Typography
            align="center"
            component="p"
            mt={0.5}
            variant="caption"
            color="text.secondary"
          >
            {i18n('app:pages.start.register.hint')}
          </Typography>
        </CardContent>
        <Divider />
        <CardContent
          sx={{
            background: (theme) => theme.palette.background.default,
            px: 4,
            py: 3,
          }}
        >
          <Typography align="center" mb={3}>
            {i18n('app:pages.start.login.description')}
          </Typography>
          <LoginForm
            disabled={loading}
            onLoading={setLoading}
            onComplete={setLogin}
          />
        </CardContent>
      </Splash>
    </Root>
  )
}
